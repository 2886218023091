import * as React from 'react';
import * as css from './OrderConfirmation.scss';
import cn from 'classnames';
import { Order } from 'api/orders/types';
import Grid from 'styleguide/components/Grid/Grid';
import IconCircleCheck from 'styleguide/icons/IconCircleCheck';
import IconClock from 'styleguide/icons/IconClock';
import IconNumeric from 'styleguide/icons/IconNumeric';
import Link from 'styleguide/components/Links/Link';
import { contactUsPath } from 'bundles/App/routes';
import IconCard from 'styleguide/components/IconCard/IconCard';
import { formatMoney } from 'utils/money';
import AddressItem from 'bundles/App/pages/OrderDetailsPage/Address/Address';
import ProductsTable from 'styleguide/components/ProductsTable/ProductsTable';
import DateTimeZone from 'styleguide/components/DateTimeZone/DateTimeZone';
import OrderContextContainer from 'contexts/OrderContext/OrderContextContainer';

const TotalItem = ({ heading, value, isBold = false }) => (
  <Grid.Row>
    <Grid.Col sm={6} className={css.heading}>
      <div>{heading}</div>
    </Grid.Col>
    <Grid.Col sm={6} className={cn(css.total, { [css.textBold]: !!isBold })}>
      <div>{value}</div>
    </Grid.Col>
  </Grid.Row>
);

interface Props {
  createdOrder?: Order;
}

const OrderConfirmation = ({ createdOrder }: Props) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {!!createdOrder && (
        <OrderContextContainer order={createdOrder}>
          <Grid.Container>
            <Grid>
              <Grid.Row>
                <Grid.Col md={1} hiddenSm="down" />
                <Grid.Col sm={12} md={10} className={css.leftAlignContainer}>
                  <div data-cy="orderConfirmed" className={css.successHeader}>
                    Order Confirmed!
                  </div>
                  <div className={css.success}>
                    We need your approval of the digital proof before we send your order into production.
                    We’ll email you the digital proof within 48 hours.
                  </div>
                </Grid.Col>
                <Grid.Col md={1} hiddenSm="down" />
              </Grid.Row>
              <Grid.Row className={css.stepsRow}>
                <Grid.Col sm={12} md={12} className={cn(css.stepsContainer, css.stepNormal)}>
                  <IconCard
                    alignment="center"
                    icon={<IconCircleCheck size="xxl" />}
                    mainText={<div className={css.cardMain}>Order Confirmed!</div>}
                    subText={
                      <>
                        {' '}
                        <div className={css.cardText}>We&apos;re checking your files!</div>
                        <div className={css.cardText}>
                          Need to update your files?{' '}
                          <Link to={contactUsPath} color="blue" underline="always">
                            Contact Us
                          </Link>
                          .
                        </div>
                      </>
                    }
                  />
                </Grid.Col>
                <Grid.Col sm={12} md={12} className={cn(css.stepsContainer, css.stepWide)}>
                  <IconCard
                    alignment="center"
                    callout
                    header={<div className={css.cardHeader}>24-48hrs</div>}
                    icon={<IconClock size="xxl" />}
                    mainText={
                      <div className={cn(css.cardMain, css.cardAttention)}>Approve Your Digital Proof</div>
                    }
                    subText={
                      <div className={css.cardText}>
                        You’ll receive an email to confirm the details of your digital proof. We’ll point out
                        anything we see that’s out of the ordinary, and will need your approval in order to
                        move forward.
                      </div>
                    }
                    cardClasses={css.extraPad}
                  />
                </Grid.Col>
                <Grid.Col sm={12} md={12} className={cn(css.stepsContainer, css.stepNormal)}>
                  <IconCard
                    alignment="center"
                    icon={<IconNumeric value="3" size="xxl" />}
                    mainText={<div className={css.cardMain}>Sent to Production</div>}
                    subText={
                      <div className={css.cardText}>
                        After you’ve approved your proof, we’ll send your order into the production queue.
                      </div>
                    }
                  />
                </Grid.Col>
              </Grid.Row>
            </Grid>
          </Grid.Container>
          <Grid.Container className={cn(css.highlight, css.infoContainer)}>
            <Grid>
              <Grid.Row className={cn(css.stepsRow, css.infoRow)}>
                <Grid.Col sm={12} lg={4} className={cn(css.stepsContainer, css.infoContainer)}>
                  <IconCard
                    alignment="top"
                    callout
                    mainText={<div className={css.infoCardHeader}>Avoid delays and rush fees!</div>}
                    subText={
                      <div className={css.infoCardBody}>
                        We want to get your products delivered on-time! If your digital proof is rejected,
                        your files revised, or approval deadline is missed, your delivery date will be pushed
                        back. If you prefer to maintain your delivery date, we’ll simply add a rush fee.
                      </div>
                    }
                    cardClasses={cn(css.cardTextAlignLeft, css.leftAlign)}
                    contentClasses={cn(css.leftAlign)}
                  />
                </Grid.Col>
                <Grid.Col sm={12} lg={4} className={cn(css.stepsContainer, css.infoContainer)}>
                  <IconCard
                    alignment="top"
                    callout
                    mainText={<div className={css.infoCardHeader}>Need to upload or update files?</div>}
                    subText={
                      <>
                        <div className={css.infoCardBody}>
                          Submit your files by 11 a.m. Pacific Time to avoid any delays!
                        </div>
                        <div className={css.infoCardBody}>
                          <Link to={contactUsPath} color="blue" underline="always">
                            Contact Us
                          </Link>
                        </div>
                      </>
                    }
                    cardClasses={cn(css.cardTextAlignLeft, css.leftAlign)}
                    contentClasses={cn(css.leftAlign)}
                  />
                </Grid.Col>
                <Grid.Col sm={12} lg={4} className={cn(css.stepsContainer, css.infoContainer)}>
                  <IconCard
                    alignment="top"
                    callout
                    mainText={<div className={css.infoCardHeader}>Design help, when you need it.</div>}
                    subText={
                      <>
                        <div className={css.infoCardBody}>
                          If your order requires more design work, we’d be happy to help. Our file correction
                          fees starts at just $10.
                        </div>
                        <div className={css.infoCardBody}>
                          <Link to={contactUsPath} color="blue" underline="always">
                            Contact Us
                          </Link>
                        </div>
                      </>
                    }
                    cardClasses={cn(css.cardTextAlignLeft, css.leftAlign)}
                    contentClasses={cn(css.leftAlign)}
                  />
                </Grid.Col>
              </Grid.Row>
            </Grid>
          </Grid.Container>
          <Grid.Container className={css.orderDetailsSpacing}>
            <Grid>
              <Grid.Row>
                <Grid.Col md={2} hiddenSm="down" />
                <Grid.Col sm={12} md={8}>
                  <Grid.Container>
                    <Grid className={cn(css.orderDetailsBorder, css.orderDetailsContainer)}>
                      <Grid.Row className={css.headerBox}>
                        <Grid.Col sm={12} md={4}>
                          <div className={css.headerTextLeft}>
                            <div className={cn(css.headerText, css.textBold)}>{createdOrder.number}</div>
                            <div className={css.headerText}>{createdOrder.customerState}</div>
                          </div>
                        </Grid.Col>
                        <Grid.Col sm={12} md={8} className={css.headerRight}>
                          <DateTimeZone
                            date={createdOrder.shipments[0].deliveryDate}
                            className={css.headerTextRight}
                            fnsFormat="M/d/yyyy h:mma"
                            preText="Delivery Date:"
                          />
                        </Grid.Col>
                      </Grid.Row>
                      <Grid.Row className={css.addressBox}>
                        <Grid.Col lg={6}>
                          <AddressItem title="Shipping Address" address={createdOrder.shippingAddress} />
                        </Grid.Col>
                        <Grid.Col lg={6}>
                          <AddressItem title="Billing Address" address={createdOrder.billingAddress} />
                        </Grid.Col>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Col>
                          <ProductsTable isLoading={false} canUpload={false} />
                        </Grid.Col>
                      </Grid.Row>
                      <Grid.Row className={css.orderTotals}>
                        <Grid.Col sm={6} lg={8} />
                        <Grid.Col sm={6} lg={4}>
                          <TotalItem heading="Sub Total" value={formatMoney(createdOrder.subtotal)} />
                          <TotalItem heading="Shipping" value={formatMoney(createdOrder.shippingTotal)} />
                          <TotalItem heading="Promo" value={formatMoney(createdOrder.promotionTotal)} />
                          <TotalItem heading="Sales Tax" value={formatMoney(createdOrder.taxTotal)} />
                          <TotalItem heading="Total" value={formatMoney(createdOrder.total)} isBold />
                        </Grid.Col>
                      </Grid.Row>
                    </Grid>
                  </Grid.Container>
                </Grid.Col>
                <Grid.Col md={2} hiddenSm="down" />
              </Grid.Row>
            </Grid>
          </Grid.Container>
        </OrderContextContainer>
      )}
      {/* <OrderNumberAndDate */}
      {/*  orderNumber={props.createdOrder.number} */}
      {/*  arrivingDate={props.createdOrder.shippingSchedule.deliveryDate.toDate()} */}
      {/* /> */}
      {/* <YourProjectSection /> */}
      {/* <InformationIconSection /> */}
      {/* <OrderSummary createdOrder={props.createdOrder} /> */}
      {/* <SocialSection /> */}
    </>
  );
};

export default OrderConfirmation;
