import { startCase, camelCase } from 'lodash-es';

export const orderStateToColor = {
  Quote: { textColor: 'text-neutral-50', backgroundColor: 'bg-neutral-400' },
  'Digital Proof Sent': { textColor: 'text-green-600', backgroundColor: 'bg-green-200' },
  'Waiting on Digital Proof Approval': { textColor: 'text-orange-600', backgroundColor: 'bg-orange-200' },
  'Waiting on Hard Copy Proof Approval': { textColor: 'text-orange-600', backgroundColor: 'bg-orange-200' },
  'Digital Proof Coming Soon': { textColor: 'text-green-600', backgroundColor: 'bg-green-200' },
  'Design Coming Soon': { textColor: 'text-green-600', backgroundColor: 'bg-green-200' },
  'Waiting For Payment': { textColor: 'text-orange-600', backgroundColor: 'bg-orange-200' },
  'Waiting on Customer Files': { textColor: 'text-orange-600', backgroundColor: 'bg-orange-200' },
  'Processing Refund/Cancel': { textColor: 'text-blue-700', backgroundColor: 'bg-blue-50' },
  'Returned/Refunded': { textColor: 'text-blue-700', backgroundColor: 'bg-blue-50' },
  'In Production': { textColor: 'text-green-600', backgroundColor: 'bg-green-200' },
  Shipping: { textColor: 'text-blue-700', backgroundColor: 'bg-blue-50' },
  'Order Shipped': { textColor: 'text-blue-700', backgroundColor: 'bg-blue-50' },
  'Ready for Pickup': { textColor: 'text-orange-600', backgroundColor: 'bg-orange-200' },
  Canceled: { textColor: 'text-red-600', backgroundColor: 'bg-red-200' },
};

export const fileStateToColor = {
  'Waiting On Files': { textColor: 'text-orange-600', backgroundColor: 'bg-orange-200' },
  'Waiting On Approval': { textColor: 'text-orange-600', backgroundColor: 'bg-orange-200' },
  'Files Uploaded': { textColor: 'text-orange-600', backgroundColor: 'bg-orange-200' },
  'Files Being Reviewed': { textColor: 'text-green-600', backgroundColor: 'bg-green-200' },
  Rejected: { textColor: 'text-red-600', backgroundColor: 'bg-red-200' },
  Approved: { textColor: 'text-blue-700', backgroundColor: 'bg-blue-50' },
};

export const paymentStateKeyToColor = {
  paid: { textColor: 'text-blue-700', backgroundColor: 'bg-blue-50' },
  credit_owed: { textColor: 'text-orange-600', backgroundColor: 'bg-orange-200' },
  balance_due: { textColor: 'text-blue-700', backgroundColor: 'bg-blue-50' },
  failed: { textColor: 'text-red-600', backgroundColor: 'bg-red-200' },
  waiting_on_credit_card: { textColor: 'text-blue-700', backgroundColor: 'bg-blue-50' },
  waiting_on_net_terms: { textColor: 'text-blue-700', backgroundColor: 'bg-blue-50' },
  waiting_to_collect_on_delivery: { textColor: 'text-blue-700', backgroundColor: 'bg-blue-50' },
  void: { textColor: 'text-blue-700', backgroundColor: 'bg-blue-50' },
  card_on_file: { textColor: 'text-blue-700', backgroundColor: 'bg-blue-50' },
  null: { textColor: 'text-blue-700', backgroundColor: 'bg-blue-50' },
  defaulted: { textColor: 'text-blue-700', backgroundColor: 'bg-blue-50' },
  waiting_on_check: { textColor: 'text-blue-700', backgroundColor: 'bg-blue-50' },
  disputed: { textColor: 'text-blue-700', backgroundColor: 'bg-blue-50' },
  waiting_on_other_payment: { textColor: 'text-blue-700', backgroundColor: 'bg-blue-50' },
};

export const shipmentStateToColor = {
  canceled: { textColor: 'text-orange-600', borderColor: 'border border-orange-600' },
  pending: { textColor: 'text-orange-600', borderColor: 'border border-orange-600' },
  shipped: { textColor: 'text-green-600', borderColor: 'border border-green-600' },
  ready_for_pickup: { textColor: 'text-green-600', borderColor: 'border border-green-600' },
  ready: { textColor: 'text-gray-700', borderColor: 'border border-gray-700' },
  ready_for_shipping_verification: { textColor: 'text-gray-700', borderColor: 'border border-gray-700' },
  partial_shipment: { textColor: 'text-gray-700', borderColor: 'border border-gray-700' },
};

export const nameFromKey = (state: string) => startCase(camelCase(state));
