import { ComboboxInput, Input as HInput, Textarea as HTextarea, InputProps } from '@headlessui/react';
import cn from 'classnames';
import { FormikProps, FormikValues, getIn } from 'formik';
import isNil from 'lodash-es/isNil';
import * as React from 'react';
import AbstractInputMessage from 'styleguide/components/Formik/AbstractInputMessage/AbstractInputMessage';
import Label from 'styleguide/components/Formik/Label/Label';
import { SvgProps } from 'styleguide/components/Svg/SvgIcon';
import IconArrowDown from 'styleguide/icons/IconArrowDown';
import { UiColor } from 'styleguide/styles/colors';
import { UiSize } from 'styleguide/styles/sizes';

import { DEFAULT_INPUT_ADDON_COLOR, DEFAULT_INPUT_ICON_PLACEMENT, DEFAULT_SIZE } from '../constants';
import type { OptionType } from '../Combobox/Combobox';

/**
 * @desc `Input`, `Select`, `Combobox`, and `Textarea` share the same styles,
 *       so this abstraction is used as a base for these components.
 */

export type AddonPlacement = 'left' | 'right' | 'topRight';

export interface BaseInputProps {
  type?: InputProps['type'];
  id?: string;
  name: string;
  value?: string | null | OptionType<string | number>;
  size?: UiSize;
  htmlSize?: string;
  className?: string;
  setRef?: React.Ref<HTMLInputElement> | React.ForwardedRef<HTMLInputElement>;
  children?: React.ReactNode;
  success?: boolean;
  disabled?: boolean;
  minRows?: number;
  onFocus?: (event: React.FocusEvent<HTMLElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLElement>) => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  label?: string;
  helperText?: string;
  Tooltip?: React.ReactNode;
  labelClassName?: string;
  errorClass?: string;
  inPlaceError?: boolean;
  form?: FormikProps<FormikValues>;
  Icon?: (props: SvgProps) => JSX.Element;
  required?: boolean;
  selectIconColor?: UiColor;
  iconPlacement?: AddonPlacement;
  iconColor?: UiColor;
  iconClassName?: string;
  displayValue?: React.ComponentProps<typeof ComboboxInput>['displayValue'];
  wrapperClassName?: string;
  hideErrorMessage?: boolean;
  onClick?: React.MouseEventHandler;
}

export type Props = BaseInputProps & {
  component: 'input' | 'select' | 'textarea' | 'Combobox' | 'datepicker';
};

const InputIcon = ({ forTextArea, iconPlacement, iconClassName, Icon, invalid, size, iconColor }) => (
  <div
    className={cn(
      `pointer-events-none absolute flex ${forTextArea ? `top-[20px]` : `top-1/2`} -translate-y-1/2`,
      iconPlacement === 'right' ? 'right-3' : 'pl-3',
      iconPlacement === 'topRight' ? '!top-[35%] right-3' : '',
    )}
  >
    {Icon && <Icon className={iconClassName} color={invalid ? 'red' : iconColor} size={size} />}
  </div>
);

const AbstractInput = ({
  component,
  size = DEFAULT_SIZE,
  htmlSize,
  value,
  setRef,
  className,
  success,
  children,
  labelClassName,
  label,
  helperText,
  Tooltip,
  form,
  Icon,
  inPlaceError = false,
  required,
  selectIconColor,
  iconPlacement = DEFAULT_INPUT_ICON_PLACEMENT,
  iconColor = DEFAULT_INPUT_ADDON_COLOR,
  iconClassName,
  wrapperClassName,
  hideErrorMessage = false,
  ...otherProps
}: Props) => {
  const invalid = !!(getIn(form?.touched, otherProps.name) && getIn(form.errors, otherProps.name));
  const INPUT_SIZE_STYLE = {
    xs: `text-xs ${Icon ? `pl-8` : `pl-[14px]`}`,
    sm: `text-sm pr-1 pb-2 ${Icon ? `pl-8` : `pl-[14px]`}`,
    md: `text-sm py-3 pr-5 ${Icon ? `pl-8` : `pl-[14px]`}`,
    lg: `text-sm py-4 pr-5 ${Icon ? `pl-8` : `pl-[14px]`}`,
  };
  const forTextArea = component === 'textarea';
  const forSelect = component === 'select';
  const forCombobox = component === 'Combobox';
  const forDatepicker = component === 'datepicker';

  let componentToRender: string | React.ComponentType = component;
  if (component === 'input') {
    componentToRender = HInput;
  } else if (forTextArea) {
    componentToRender = HTextarea;
  }

  const inputStyle = `font-hvLite block bg-shades-0 rounded-lg w-full text-sm border-2 border-solid border-gray-200 
  appearance-none focus:outline-none text-default focus:ring-0 disabled:bg-gray-light focus:border-blue peer
  ${invalid ? '!border-solid !border-2 border-red' : ''} 
  ${success ? '!border-solid !border-2 border-blue' : ''}`;

  return (
    <>
      <div className={cn('w-full', wrapperClassName)}>
        <div className="relative">
          {forCombobox ? (
            <div
              className={cn(
                'rounded-lg border-2 border-solid border-gray-200 group-aria-expanded:border-blue',
                invalid && 'border-red',
              )}
            >
              <ComboboxInput
                ref={setRef}
                placeholder=" "
                className={cn(
                  inputStyle,
                  INPUT_SIZE_STYLE[size],
                  'truncate',
                  className,
                  'cursor-pointer !border-none focus:cursor-text',
                )}
                onKeyDown={ev => {
                  // https://github.com/tailwindlabs/headlessui/discussions/1687
                  ev.stopPropagation();
                }}
                {...otherProps}
              />
              {label && (
                <Label
                  placement="float"
                  invalid={invalid}
                  className={cn('font-hvMedium', labelClassName)}
                  forCombobox
                  required={required}
                  inputWithIcon={!!Icon}
                  inputSize={size}
                  headlessLabel
                >
                  {label}
                </Label>
              )}
              {children}
            </div>
          ) : (
            <>
              {Tooltip && (
                <div
                  className={`absolute flex right-3 z-100 h-4 -translate-y-1/2
                  ${forTextArea ? 'top-[20px]' : 'top-1/2'} 
                  ${forSelect ? 'mr-2' : ''}`}
                >
                  {Tooltip}
                </div>
              )}
              {React.createElement(
                forDatepicker ? 'input' : componentToRender,
                {
                  // @ts-ignore
                  ref: setRef,
                  value: isNil(value) ? '' : value,
                  size: htmlSize,
                  placeholder: ' ',
                  className: cn(inputStyle, INPUT_SIZE_STYLE[size], className),
                  ...otherProps,
                },
                children,
              )}
            </>
          )}
          {label && !forCombobox && (
            <Label
              placement="float"
              invalid={invalid}
              className={cn('font-hvMedium', labelClassName)}
              forTextArea={forTextArea}
              required={required}
              inputWithIcon={!!Icon}
              inputSize={size}
              headlessLabel
            >
              {label}
            </Label>
          )}
          <InputIcon
            forTextArea={forTextArea}
            size={size}
            iconPlacement={iconPlacement}
            iconClassName={iconClassName}
            Icon={Icon}
            invalid={invalid}
            iconColor={iconColor}
          />
          {(forSelect || forDatepicker || forCombobox) && (
            <IconArrowDown
              className="pointer-events-none absolute right-3 top-1/2 flex !h-2 !w-2 -translate-y-1/2 cursor-pointer text-default"
              aria-hidden="true"
              color={selectIconColor}
            />
          )}
        </div>
      </div>
      {!hideErrorMessage && (
        <AbstractInputMessage
          inPlaceError={inPlaceError}
          invalid={invalid}
          touched={getIn(form?.touched, otherProps.name)}
          error={getIn(form?.errors, otherProps.name)}
          helperText={helperText}
        />
      )}
    </>
  );
};

export default AbstractInput;
