import * as React from 'react';
import OrderContext from 'contexts/OrderContext/OrderContext';
import DateTimeZone from 'styleguide/components/DateTimeZone/DateTimeZone';

const DeliveryStepDone = () => {
  const { order } = React.useContext(OrderContext);

  return (
    <div className="flex flex-col">
      {order.shipments.map((shipment, i) => (
        <div className="mb-4">
          <div className="paragraph-bold-mobile mb-1">{`Shipment ${i + 1}: Scheduled Delivery`}</div>
          {shipment.cost === 0.0 && <div className="caption-bold text-green-500">Free Shipping!</div>}
          <DateTimeZone
            date={shipment.deliveryDate}
            className="paragraph-bold-mobile"
            fnsFormat="MMMM do yyyy h:mma"
            showEndOfDay
          />
        </div>
      ))}
    </div>
  );
};
export default DeliveryStepDone;
