import {
  Adjustment,
  CustomerReturn,
  FileVersion,
  LineItem,
  Order,
  OrderTotalsHash,
  OrderUpdate,
  Payment,
  Pickup,
  ProductionCheckpoint,
  Promotion,
  PurchasedShipment,
  Refund,
  Reimbursement,
  ReturnAuthorization,
  ReturnItem,
  Shipment,
  Upload,
} from 'api/orders/types';
import {
  Comment,
  Facility,
  ProductionItem,
  ProductionItemGroup,
  PrepressLog,
  Reservation,
} from 'app/api/admin/orders/types';
import { Address } from 'app/bundles/App/pages/Account/Addresses/types';
import { ProofApproval } from 'app/bundles/App/pages/ProofApprovalsPage/types';
import { StoreType } from '../AppContext/types';
import { SimplyShipError } from './OrderContext';

export const updateTotals = (order: Order, totals: OrderTotalsHash): Order => {
  const newOrder = { ...order };
  newOrder.total = totals.total;
  newOrder.orderTotalAfterStoreCredit = totals.orderTotalAfterStoreCredit;
  newOrder.totalApplicableStoreCredit = totals.totalApplicableStoreCredit;
  newOrder.lineItemTotals = totals.lineItemTotals;
  newOrder.promotionTotal = totals.promotionTotal;
  newOrder.shippingCost = totals.shippingCost;
  newOrder.shippingTotal = totals.shippingTotal;
  newOrder.subtotal = totals.subtotal;
  newOrder.taxTotal = totals.taxTotal;
  newOrder.outstandingBalance = totals.outstandingBalance;
  newOrder.token = totals.token;
  if (totals.totalSheets && !!order.admin) {
    newOrder.admin.totalSheets = totals.totalSheets;
  }

  return newOrder;
};

export type ActionsMap = {
  setStatus: { status: 'loading' | 'loaded' | SimplyShipError };
  setOrder: { order: Order };
  updateReadyAt: { readyAt: string };
  setReferenceNumber: { referenceNumber: string };
  setShipmentCustomized: { id: number; isCustomized: boolean };
  updateTotals: { orderTotals: OrderTotalsHash };
  addUploadToFileVersion: { fileVersionId: number; upload: Upload };
  removeUploadToFileVersion: { id: number };
  addUploadToProofApproval: { proofApprovalId: number; upload: Upload };
  removeUploadToProofApproval: { id: number };
  addLineItem: { lineItem: LineItem };
  removeLineItem: { id: number };
  updateLineItem: { id: number; lineItem: LineItem };
  updateAdjustments: { adjustments: Adjustment[] };
  updateAdjustment: { adjustment: Adjustment };
  removeAdjustment: { id: number };
  addProofApproval: { proofApproval: ProofApproval };
  removeProofApproval: { id: number };
  updateProofApproval: { proofApproval: ProofApproval };
  updateBillAddress: { address: Address };
  updateShipAddress: { address: Address };
  setPaymentState: { paymentState: string };
  setFolderName: { folderName: string };
  setPostApprovalState: { postApprovalState: string };
  setStoreType: { storeType: StoreType };
  setState: { state: string };
  setReservation: { reservation: Reservation };
  setEmail: { email: string };
  setFacility: { facility: Facility };
  addPayments: { payments: Payment[] };
  updatePayment: { payment: Payment };
  addRefund: { refund: Refund };
  updateRefunds: { refunds: Refund[] };
  updateRiskiness: { isRisky: boolean };
  updateProductionItem: { productionItem: ProductionItem };
  updateProductionCheckpoints: { productionCheckpoints: ProductionCheckpoint[] };
  updateProductionItems: { productionItems: ProductionItem[] };
  updateProductionItemGroups: { productionItemGroups: ProductionItemGroup[] };
  updateComments: { comments: Comment[] };
  addComment: { comment: Comment };
  removeComment: { id: number };
  updateComment: { comment: Comment };
  addPrepressLog: { prepressLog: PrepressLog };
  updatePrepressLog: { prepressLog: PrepressLog };
  addPurchasedShipment: { purchasedShipment: PurchasedShipment };
  removePurchasedShipment: { id: number };
  addPickup: { pickup: Pickup; purchasedShipmentId: number };
  removePickup: { id: number; purchasedShipmentId: number };
  updatePurchasedShipment: { purchasedShipment: PurchasedShipment; oldPurchasedShipmentId: number };
  createShipment: { shipment: Shipment };
  updateShipment: { id: number; shipment: Shipment };
  destroyShipment: { shipment: Shipment };
  updateProductionDays: { productionDays: number };
  updateReturnAuthorizations: { returnAuthorizations: ReturnAuthorization[] };
  updateCustomerReturns: { customerReturns: CustomerReturn[] };
  updateUnreturnedItems: { unreturnedItems: ReturnItem[] };
  addReimbursement: { reimbursement: Reimbursement };
  addOrderUpdate: { orderUpdate: OrderUpdate };
  updateFileVersions: { fileVersions: FileVersion[]; lineItemId: number };
  updatePromotions: { promotions: Promotion[] };
  updateOrder: Order;
};

export type OrderActions = {
  [Key in keyof ActionsMap]: {
    type: Key;
    payload: ActionsMap[Key];
  };
}[keyof ActionsMap];

export const orderReducer = (order: Order, action: OrderActions): Order => {
  switch (action.type) {
    case 'setStatus':
      return { ...order, status: action.payload.status };
    case 'setOrder':
      return action.payload.order;
    case 'updateReadyAt':
      return { ...order, readyAt: action.payload.readyAt };
    case 'setReferenceNumber':
      return { ...order, referenceNumber: action.payload.referenceNumber };
    case 'setShipmentCustomized': {
      const shipments = order.shipments.map(s =>
        s.id === action.payload.id ? { ...s, isCustomized: action.payload.isCustomized } : s,
      );

      return { ...order, shipments };
    }
    case 'updateTotals':
      return updateTotals(order, action.payload.orderTotals);
    case 'addUploadToFileVersion': {
      const lineItem = order.lineItems.find(li =>
        li.fileVersions.some(fv => fv.id === action.payload.fileVersionId),
      );
      const fileVersion = lineItem.fileVersions.find(fv => fv.id === action.payload.fileVersionId);
      fileVersion.uploads.push(action.payload.upload);

      return {
        ...order,
        lineItems: order.lineItems.map(li => ({
          ...li,
          fileVersions: [...li.fileVersions.map(fv => (fv.id === fileVersion.id ? fileVersion : fv))],
        })),
      };
    }
    case 'removeUploadToFileVersion': {
      const lineItem = order.lineItems.find(li =>
        li.fileVersions.find(fv => fv.uploads.some(u => u.id === action.payload.id)),
      );
      const fileVersion = lineItem.fileVersions?.find(fv => fv.uploads.some(u => u.id === action.payload.id));
      const newFileVersion = {
        ...fileVersion,
        uploads: [...fileVersion.uploads.filter(upload => upload.id !== action.payload.id)],
      };

      return {
        ...order,
        lineItems: order.lineItems.map(li => ({
          ...li,
          fileVersions: [...li.fileVersions.map(fv => (fv.id === newFileVersion.id ? newFileVersion : fv))],
        })),
      };
    }
    case 'addUploadToProofApproval': {
      const proofApproval = order.proofApprovals.find(elem => elem.id === action.payload.proofApprovalId);
      proofApproval.uploads.push(action.payload.upload);

      return {
        ...order,
        proofApprovals: order.proofApprovals.map(elem =>
          elem.id === proofApproval.id ? proofApproval : elem,
        ),
      };
    }
    case 'removeUploadToProofApproval':
      return {
        ...order,
        proofApprovals: order.proofApprovals.map(proofApproval => ({
          ...proofApproval,
          files: [...proofApproval.uploads.filter(upload => upload.id !== action.payload.id)],
        })),
      };
    case 'addLineItem':
      return { ...order, lineItems: [...order.lineItems, action.payload.lineItem] };
    case 'removeLineItem':
      return { ...order, lineItems: [...order.lineItems.filter(elem => elem.id !== action.payload.id)] };
    case 'updateLineItem': {
      const lineItems = order.lineItems.map(lineItem => {
        if (lineItem.id === action.payload.id) {
          return action.payload.lineItem;
        }
        return lineItem;
      });

      return { ...order, lineItems };
    }
    case 'updateAdjustments':
      return { ...order, adjustments: action.payload.adjustments };
    case 'updateAdjustment': {
      const adjustments = order.adjustments.map(adjustment => {
        if (adjustment.id === action.payload.adjustment.id) {
          return action.payload.adjustment;
        }
        return adjustment;
      });

      return { ...order, adjustments };
    }
    case 'removeAdjustment':
      return { ...order, adjustments: [...order.adjustments.filter(elem => elem.id !== action.payload.id)] };
    case 'addProofApproval':
      return { ...order, proofApprovals: [...order.proofApprovals, action.payload.proofApproval] };
    case 'removeProofApproval':
      return {
        ...order,
        proofApprovals: [...order.proofApprovals.filter(elem => elem.id !== action.payload.id)],
      };
    case 'updateProofApproval': {
      const proofApprovals = order.proofApprovals.map(proof => {
        if (proof.id === action.payload.proofApproval.id) {
          return action.payload.proofApproval;
        }
        return proof;
      });

      return { ...order, proofApprovals };
    }
    case 'updateBillAddress':
      return { ...order, billingAddress: action.payload.address };
    case 'updateShipAddress':
      return { ...order, shippingAddress: action.payload.address };
    case 'setPaymentState':
      return { ...order, paymentState: action.payload.paymentState };
    case 'setFolderName':
      return { ...order, admin: { ...order.admin, folderName: action.payload.folderName } };
    case 'setPostApprovalState':
      return { ...order, admin: { ...order.admin, postApprovalState: action.payload.postApprovalState } };
    case 'setStoreType':
      return { ...order, storeType: action.payload.storeType };
    case 'setState':
      return { ...order, state: action.payload.state };
    case 'setReservation': {
      switch (action.payload.reservation.type) {
        case 'Mgx::Reservation::Csr':
          return { ...order, admin: { ...order.admin, csrReservation: action.payload.reservation } };
        case 'Mgx::Reservation::Prepress':
          return { ...order, admin: { ...order.admin, prepressReservation: action.payload.reservation } };
        case 'Mgx::Reservation::PrepressVerification':
          return {
            ...order,
            admin: { ...order.admin, prepressVerificationReservation: action.payload.reservation },
          };
        default:
          return order;
      }
    }
    case 'setEmail':
      return { ...order, email: action.payload.email };
    case 'setFacility':
      return { ...order, admin: { ...order.admin, facility: action.payload.facility } };
    case 'addPayments':
      return { ...order, payments: [...order.payments, ...action.payload.payments] };
    case 'updatePayment': {
      const payments = order.payments.map(payment => {
        if (payment.id === action.payload.payment.id) {
          return action.payload.payment;
        }
        return payment;
      });

      return { ...order, payments };
    }
    case 'addRefund':
      return { ...order, refunds: [...order.refunds, action.payload.refund] };
    case 'updateRefunds':
      return { ...order, refunds: action.payload.refunds };
    case 'updateRiskiness':
      return { ...order, isRisky: action.payload.isRisky };
    case 'updateProductionItem': {
      const productionItems = order.admin.productionItems.map(pi => {
        if (pi.id === action.payload.productionItem.id) {
          return action.payload.productionItem;
        }
        return pi;
      });

      return { ...order, admin: { ...order.admin, productionItems } };
    }
    case 'updateProductionCheckpoints':
      return { ...order, productionCheckpoints: action.payload.productionCheckpoints };
    case 'updateProductionItems':
      return { ...order, admin: { ...order.admin, productionItems: action.payload.productionItems } };
    case 'updateProductionItemGroups':
      return {
        ...order,
        admin: { ...order.admin, productionItemGroups: action.payload.productionItemGroups },
      };
    case 'updateComments':
      return { ...order, admin: { ...order.admin, comments: action.payload.comments } };
    case 'addComment':
      return {
        ...order,
        admin: { ...order.admin, comments: [...order.admin.comments, action.payload.comment] },
      };
    case 'removeComment':
      return {
        ...order,
        admin: {
          ...order.admin,
          comments: order.admin.comments.filter(elem => elem.id !== action.payload.id),
        },
      };
    case 'updateComment': {
      const comments = order.admin.comments.map((comment: Comment) => {
        if (comment.id === action.payload.comment.id) {
          return action.payload.comment;
        }
        return comment;
      });

      return { ...order, admin: { ...order.admin, comments } };
    }
    case 'addPrepressLog':
      return {
        ...order,
        admin: {
          ...order.admin,
          prepressLog: { ...action.payload.prepressLog },
        },
      };
    case 'updatePrepressLog':
      return {
        ...order,
        admin: {
          ...order.admin,
          prepressLog: { ...order.admin.prepressLog, ...action.payload.prepressLog },
        },
      };
    case 'addPurchasedShipment':
      return {
        ...order,
        purchasedShipments: [...order.purchasedShipments, action.payload.purchasedShipment],
      };
    case 'removePurchasedShipment':
      return {
        ...order,
        purchasedShipments: [...order.purchasedShipments.filter(elem => elem.id !== action.payload.id)],
      };
    case 'updatePurchasedShipment': {
      return {
        ...order,
        purchasedShipments: [
          ...order.purchasedShipments.filter(elem => elem.id !== action.payload.oldPurchasedShipmentId),
          action.payload.purchasedShipment,
        ],
      };
    }
    case 'addPickup': {
      const purchasedShipment = order.purchasedShipments.find(
        ps => ps.id === action.payload.purchasedShipmentId,
      );

      const newPurchasedShipment = {
        ...purchasedShipment,
        pickups: [...purchasedShipment.pickups, action.payload.pickup],
      };

      return {
        ...order,
        purchasedShipments: order.purchasedShipments.map(ps =>
          ps.id === newPurchasedShipment.id ? newPurchasedShipment : ps,
        ),
      };
    }
    case 'removePickup': {
      return {
        ...order,
        purchasedShipments: order.purchasedShipments.map(purchaseShipment => ({
          ...purchaseShipment,
          pickups: [...purchaseShipment.pickups.filter(pickup => pickup.id !== action.payload.id)],
        })),
      };
    }
    case 'createShipment':
      return { ...order, shipments: [...order.shipments, action.payload.shipment] };
    case 'updateShipment': {
      let shipments: Shipment[];
      if (order.shipments.some(shipment => shipment.id === action.payload.id)) {
        shipments = order.shipments.map(s => (s.id === action.payload.id ? action.payload.shipment : s));
      } else {
        shipments = [...order.shipments, action.payload.shipment];
      }
      return { ...order, shipments };
    }
    case 'destroyShipment':
      return {
        ...order,
        shipments: order.shipments.filter(s => s.id !== action.payload.shipment.id),
      };
    case 'updateProductionDays':
      return { ...order, productionDays: action.payload.productionDays };
    case 'updateReturnAuthorizations':
      return { ...order, returnAuthorizations: action.payload.returnAuthorizations };
    case 'updateCustomerReturns':
      return { ...order, customerReturns: action.payload.customerReturns };
    case 'updateUnreturnedItems':
      return { ...order, unreturnedItems: action.payload.unreturnedItems };
    case 'addReimbursement':
      return { ...order, reimbursements: [...order.reimbursements, action.payload.reimbursement] };
    case 'addOrderUpdate':
      return { ...order, updates: [...order.updates, action.payload.orderUpdate] };
    case 'updateFileVersions': {
      const lineItems = order.lineItems.map(li => {
        if (li.id === action.payload.lineItemId) {
          return { ...li, fileVersions: action.payload.fileVersions };
        }
        return li;
      });
      return { ...order, lineItems };
    }
    case 'updatePromotions':
      return { ...order, promotions: action.payload.promotions };
    case 'updateOrder':
      return action.payload;
    default:
      throw new Error(`unknown action ${action}`);
  }
};
