import * as React from 'react';
import * as css from './AdditionalInfo.scss';
import { H4 } from 'styleguide/components/Heading';
import HtmlContent from 'styleguide/components/HtmlContent/HtmlContent';
import Grid from 'styleguide/components/Grid/Grid';

interface SegmentProps {
  content: string | HTMLElement | React.ReactElement | React.ReactNode;
  containerClasses?: string;
  title?: string;
  id?: string;
  cms?: boolean;
}
const BodyWrapper = ({ ...props }) => (
  <div className="mt-10">{(props.cms && <HtmlContent content={props.children} />) || props.children}</div>
);

const Segment = ({ content, containerClasses, title, id, cms }: SegmentProps) => (
  <>
    {!!id && <div id={id} className={css.anchorOffset} />}
    <div
      id={`${id}-body`}
      className="border-b border-t border-solid border-gray-50 py-16 xs:px-6 md:px-[100px] 3xl:px-0"
    >
      <Grid className="w-full">
        <Grid.Row>
          <div className="relative !flex w-full !items-center p-0 px-1 md:px-3">
            <H4>{title}</H4>
          </div>
        </Grid.Row>
        <BodyWrapper cms={cms} className={containerClasses}>
          {content}
        </BodyWrapper>
      </Grid>
    </div>
  </>
);

interface Props {
  TemplateSpecs?: React.ReactNode;
  Articles?: React.ReactNode;
  FAQ?: React.ReactNode;
  faqHeading?: string;
  Reviews?: React.ReactNode;
  SEOBlockTitle?: string;
  SEOBlock?: string;
  additionalContent?: string;
}

const AdditionalInfo = ({ ...props }: Props) => (
  <div>
    {!!props.Reviews && <Segment id="reviews" title="Reviews" content={props.Reviews} />}
    {!!props.TemplateSpecs && (
      <Segment id="templates-and-specs" title="Templates &amp; specs" content={props.TemplateSpecs} />
    )}
    {!!props.Articles && props.Articles}
    {!!props.FAQ && <Segment id="faq" title="FAQs" content={props.FAQ} />}
    {!!props.additionalContent && (
      <Segment id="more-resources" cms title="The Printivity Difference" content={props.additionalContent} />
    )}
    {!!props.SEOBlock && (
      <Segment
        id="more-information"
        cms
        title={props.SEOBlockTitle ? props.SEOBlockTitle : 'More Information'}
        content={props.SEOBlock}
      />
    )}
  </div>
);

export default AdditionalInfo;
