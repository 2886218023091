import * as React from 'react';
import { trackCmsPage, trackProductChange } from 'api/CmsPages';
import {
  addToCart,
  productPage,
  productSelection,
  clickRelatedProduct,
  clickAddToCart,
  pdpUserProperties,
} from 'api/gtm';
import { doRushDeliveryRequest } from 'api/simplyShip';
import {
  CmsContentSection,
  RelatedItems,
  InnerNavigation,
  AdditionalInfo,
  Reviews,
  FAQ,
  Articles,
  TemplateSpecs,
} from './Dynamic.imports-loadable';
import { cartPath } from 'bundles/App/routes';
import AppContext from 'contexts/AppContext/AppContext';
import { CmsProductPage, Taxon } from 'contexts/AppContext/types';
import { doAddLineItem } from 'contexts/OrderContext/actions';
import OrderContext from 'contexts/OrderContext/OrderContext';
import PersephoneContext from 'contexts/PersephoneContextContainer/PersephoneContext';
import QuoteContextContainer from 'contexts/QuoteContext/QuoteContextContainer';
import UserContext from 'contexts/UserContextContainer/UserContext';
import { Status } from 'libs/utils/api/types';
import { KeyVal } from 'libs/utils/common-types';
import { useHistory } from 'react-router';
import Footer from 'styleguide/components/Footer/Footer';
import Grid from 'styleguide/components/Grid/Grid';
import { H4 } from 'styleguide/components/Heading';
import Meta from 'styleguide/components/Meta/Meta';
import ProductCard from 'styleguide/components/NavigationCard/NavigationCard';
import ProductJsonLd from 'styleguide/components/ProdeuctJsonLd/ProductJsonLd';
import PromotionBanner from 'styleguide/components/PromotionBannner/PromotionBanner';
import { findTaxon } from 'utils/flattenTaxons';
import AddToCart from './AddToCart/AddToCart';
import InstantQuote from './InstantQuote/InstantQuote';
import BackToTop from './BackToTop/BackToTop';
import ProductDetailsNavbar from './ProductDetailsNavbar/ProductDetailsNavbar';
import { quoteKey } from 'app/constants';
import { setLocalStorage, getLocalStorage } from 'utils/LocalStorageService';
import { useLocation } from 'react-router-dom';
import useQuoteParams from './hooks/useQuoteParams';
import useCms from 'utils/hooks/useCms';
import useSelected from './hooks/useSelected';
import LoaderWrapper from 'styleguide/components/LoaderWrapper/LoaderWrapper';
import LazyLoad from 'react-lazyload';

export interface RushDeliveryData {
  minDate: string;
  seconds: number;
}

const relatedItemsLoadWrapperHeight = 'h-[338px]';

const ProductDetailsPage = () => {
  const location = useLocation();
  const history = useHistory();
  const { currentUser } = React.useContext(UserContext);
  const { dispatch } = React.useContext(OrderContext);
  const persephoneContext = React.useContext(PersephoneContext);
  const appContext = React.useContext(AppContext);
  const taxonomy = appContext.store.taxonomies.find(elem => elem.name === 'Products');
  const cms = useCms<CmsProductPage>();
  const [rushDeliveryData, setRushDeliveryData] = React.useState<RushDeliveryData>(null);
  const { values: quoteValues, valuesNotEmpty: quoteValuesNotEmpty } = useQuoteParams();

  const { products, selectedProduct, selectedProductCms, selectedCategoryCms, selectProduct, productSlug } =
    useSelected();

  const alias = cms.productTiles ? cms.fullPath.split('/').slice(-1)[0] : null;
  const isMPDP = products.length > 1;
  const hideNewCustomerPromotion: boolean = location.pathname.includes('hardcover-books');

  const onChange = ({ lineItem }) => {
    setRushDeliveryData(null);
    doRushDeliveryRequest(selectedProduct.slug, lineItem.productionRange).then(res2 => {
      if (res2.status === Status.Ok) {
        setRushDeliveryData(res2.payload);
      }
    });
  };

  const productSelectionHandler = (selectedProductSlug: string, e) => {
    e.preventDefault();
    selectProduct(selectedProductSlug);
  };

  React.useEffect(() => {
    trackCmsPage(cms.id);
  }, [cms?.id, persephoneContext.catalog]);

  React.useEffect(() => {
    productPage(
      cms.label,
      products.map(elem => ({ item_name: elem.name, item_id: elem.slug })),
    );
  }, [products]);

  React.useEffect(() => {
    productSelection(cms.label, {
      item_name: selectedProduct.name,
      item_id: selectedProduct.slug,
    });
  }, [selectedProduct]);

  React.useEffect(() => {
    if (currentUser && cms.productTiles) {
      trackProductChange(selectedProductCms.id);
    }
    pdpUserProperties({ loggedIn: !!currentUser, email: currentUser?.email });
  }, [selectedProductCms, currentUser]);

  const onAddToCartClick = (values: KeyVal, price: number) => {
    const quote = getLocalStorage<Record<string, unknown>>(quoteKey);
    doAddLineItem(dispatch, values, alias).then(res => {
      if (res) {
        Promise.all([
          addToCart({
            item_name: selectedProduct.name,
            item_id: selectedProduct.slug,
            price: price / parseFloat(values['document-count']),
            quantity: parseFloat(values['document-count']),
          }),
          clickAddToCart({
            item_name: selectedProduct.name,
            item_id: selectedProduct.slug,
            price: price / parseFloat(values['document-count']),
            quantity: parseFloat(values['document-count']),
            ...values,
          }),
        ]).then(() => {
          const updatedQuote = { ...quote };
          delete updatedQuote[`${productSlug}_${location.pathname}`];
          setLocalStorage(quoteKey, updatedQuote);
          history.push(cartPath);
        });
      }
    });
  };

  const getBanner = () => {
    let banner = null;

    if (!!cms.displayPromotion && !!cms.displayPromotion.showBanner) {
      banner = cms.displayPromotion;
    }
    return banner;
  };

  const getDiscountInfo = () => {
    let percent = 0;
    let message = '';

    if (cms.displayPromotion) {
      percent = cms.displayPromotion.discountPercent;
      message = cms.displayPromotion.discountMessage;
    }
    return { discountPercent: percent, discountMessage: message };
  };

  const isLoading = !selectedProduct;

  // TODO: temporary show shortDesc if shortDescription not set
  const shortDescription = isMPDP
    ? cms && cms.shortDesc
    : !!selectedProductCms && selectedProductCms.shortDesc;
  const features = isMPDP ? !!cms && cms.features : !!selectedProductCms && selectedProductCms.features;
  const branding = isMPDP ? !!cms && cms.branding : !!selectedProductCms && selectedProductCms.branding;
  const additionalContent = !!selectedProductCms && selectedProductCms.additionalContent;
  const description = isMPDP
    ? !!cms && cms.description
    : !!selectedProductCms && selectedProductCms.description;
  const relatedItems = !!selectedProductCms && selectedProductCms.relatedItems;
  const isTemplateSpecsDisabled = !!selectedProductCms && !cms.downloads && !selectedProductCms.specs;
  const isFaqDisabled = isMPDP ? !!cms && !cms.faqs : !!selectedProductCms && !selectedProductCms.faqs;
  const articlesRow = null; // selectedCategoryCms.rows[3];
  const isArticlesDisabled = !(
    !!selectedProductCms &&
    !!articlesRow &&
    articlesRow?.type === 'Comfy::Cms::Page::Row::InfoBlock' &&
    articlesRow?.content
  );
  const promotionBanner = getBanner();
  const { discountPercent, discountMessage } = getDiscountInfo();
  const informational = false;
  const useStandardButton: boolean = true;

  return (
    <>
      <Meta
        title={cms.metaData.metaTitle}
        description={cms.metaData.metaDescription}
        keywords={cms.metaData.metaKeywords}
        canonical={cms.metaData.metaCanonicalLink}
        faqs={cms.faqs}
      />
      <ProductDetailsNavbar
        disableFeatures={!features && !description}
        disableBranding={!branding}
        disableTemplates={isTemplateSpecsDisabled}
        disableReviews={false}
        disableFAQ={isFaqDisabled}
        disableMoreResources={!additionalContent}
        disableSEOBlock={!cms.content}
        disableArticles={isArticlesDisabled}
      />
      <QuoteContextContainer
        productSlug={selectedProduct.slug}
        alias={alias}
        onChange={onChange}
        onSubmit={onAddToCartClick}
        discountPercent={discountPercent}
        discountMessage={discountMessage}
        productionDays={null}
        displayNotes={false}
        showHiddenOptions={false}
        instantQuote={!useStandardButton}
        cacheQuotes
        initialValues={quoteValuesNotEmpty ? quoteValues : null}
      >
        {!isLoading && (
          <>
            <ProductJsonLd product={selectedProduct} productPage={selectedProductCms} />
            <Grid.Container>
              <Grid>
                <Grid.Row className="mb-4">
                  <Grid.Col md={1} />
                  <Grid.Col md={10}>
                    {promotionBanner ? <PromotionBanner displayPromotion={promotionBanner} /> : null}
                  </Grid.Col>
                  <Grid.Col md={1} />
                </Grid.Row>
                <InstantQuote
                  shortDescription={shortDescription}
                  reviewsCount={77}
                  reviewsScore={5}
                  selectedProduct={selectedProduct}
                  selectedCategoryCms={selectedCategoryCms}
                  selectedProductCms={selectedProductCms}
                  products={products}
                  informational={informational}
                  cms={cms}
                  productSelectionHandler={productSelectionHandler}
                />
              </Grid>
            </Grid.Container>
            <LazyLoad
              once
              placeholder={LoaderWrapper(
                `${relatedItemsLoadWrapperHeight}  flex items-center justify-center`,
              )}
            >
              {!!relatedItems && (
                <RelatedItems title={relatedItems.heading}>
                  {relatedItems.items.map((item, index) => {
                    const txn: Taxon = findTaxon(taxonomy.taxons, item.name);
                    return (
                      txn && (
                        <div
                          key={`${txn.pageId}-${index}`}
                          onClick={() => {
                            clickRelatedProduct({
                              item_name: txn.name,
                              item_id: `${txn.pageId}`,
                            });
                          }}
                          onKeyDown={() => {}}
                          role="button"
                          tabIndex={0}
                        >
                          <ProductCard
                            className="my-4"
                            alt={txn.image.alt}
                            image={txn.image.url}
                            title={txn.name}
                            url={txn.pagePath}
                          />
                        </div>
                      )
                    );
                  })}
                </RelatedItems>
              )}
            </LazyLoad>
            <div id="innerNav" />
            <InnerNavigation
              disableFeatures={!features && !description}
              disableBranding={!branding}
              disableTemplates={isTemplateSpecsDisabled}
              disableReviews={false}
              disableFAQ={isFaqDisabled}
              disableMoreResources={!additionalContent}
              disableSEOBlock={!cms.content}
              disableArticles={isArticlesDisabled}
            />
            {!!features && (
              <CmsContentSection
                id="features"
                content={isMPDP ? cms.features : selectedProductCms.features}
                className="bg-neutral-50"
              />
            )}
            {!!description && (
              <CmsContentSection
                id={!features && 'features'}
                content={isMPDP ? cms.description : selectedProductCms.description}
                className="!max-w-full bg-neutral-50"
                bodyClassName="max-w-base mx-auto"
              />
            )}
            {!!branding && (
              <CmsContentSection
                id="why-printivity"
                heading={<H4 className="mb-10">Why Printivity</H4>}
                content={isMPDP ? cms.branding : selectedProductCms.branding}
              />
            )}
            <Reviews />
            <AdditionalInfo
              TemplateSpecs={
                isTemplateSpecsDisabled ? null : (
                  <TemplateSpecs
                    downloads={
                      cms.fullPath === '/marketing/calendars' ? cms.downloads : selectedProductCms.downloads
                    }
                    specs={cms.fullPath === '/marketing/calendars' ? cms.specs : selectedProductCms.specs}
                    cmsPage={cms}
                  />
                )
              }
              Articles={
                isArticlesDisabled
                  ? null
                  : articlesRow.type === 'Comfy::Cms::Page::Row::InfoBlock' && (
                      <Articles infoBlock={articlesRow} />
                    )
              }
              FAQ={isFaqDisabled ? null : <FAQ questions={isMPDP ? cms.faqs : selectedProductCms.faqs} />}
              SEOBlock={!!cms.content && isMPDP ? cms.content : selectedProductCms.content}
              additionalContent={
                !!additionalContent && isMPDP ? cms.additionalContent : selectedProductCms.additionalContent
              }
            />
            <Footer withoutSubscriptionForm={hideNewCustomerPromotion} />
            <AddToCart informational={informational} rushDeliveryData={rushDeliveryData} />
            <BackToTop />
          </>
        )}
      </QuoteContextContainer>
    </>
  );
};

export default ProductDetailsPage;
