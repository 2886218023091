import * as React from 'react';
import cn from 'classnames';
import * as css from './ReviewStars.scss';
import range from 'lodash-es/range';
import { IconStar } from 'styleguide/icons';
import { UiColor } from 'styleguide/styles/colors';
import IconStarHollow from 'styleguide/icons/IconStarHollow';

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  color?: UiColor;
  count: number;
  max?: number;
}

const ReviewStars = ({ color, count, max = 5, className, ...otherProps }: Props) => (
  <div className={cn(css.ReviewStars, 'mt-1', className)} {...otherProps}>
    {range(0, count).map(n => (
      <IconStar key={n} size="sm" color={color} className={css.star} />
    ))}
    {range(0, max - count).map(n => (
      <IconStarHollow key={n} size="sm" color={color} className={css.star} />
    ))}
  </div>
);

export default ReviewStars;
