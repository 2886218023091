import { Address } from 'bundles/App/pages/Account/Addresses/types';
import AddressDescription from 'bundles/App/pages/CheckoutPage/Steps/AddressDescription/AddressDescription';
import cn from 'classnames';
import AppContext from 'contexts/AppContext/AppContext';
import { Field, FormikValues, useFormikContext } from 'formik';
import * as React from 'react';
import TwoColumnFormField from 'styleguide/components/forms/Field/TwoColumnFormField';
import Button from 'styleguide/components/Button/Button';
import type { OptionType } from 'styleguide/components/Formik/Combobox/Combobox';
import { getStates } from 'api/states';
import { Status } from 'libs/utils/api/types';
import FormikFieldWrapper from '../FormikFieldWrapper/FormikFieldWrapper';

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  address?: Address;
  prefix: string;
  addressFormTitle?: React.ReactNode;
  zipWarning?: boolean;
  poBoxWarning?: boolean;
  className?: string;
  disabled?: boolean;
  newAddress?: boolean;
  setNewAddress?: () => void;
  checkoutMode?: boolean;
  international?: boolean;
  validCountries?: string[];
}

const appendName = (prefix, name) => {
  if (prefix === '') {
    return name;
  }
  return `${prefix}.${name}`;
};

const AddressForm = ({
  addressFormTitle,
  address,
  newAddress,
  setNewAddress,
  prefix,
  disabled,
  poBoxWarning,
  zipWarning,
  international,
  validCountries,
  ...props
}: Props) => {
  const appContext = React.useContext(AppContext);
  const [states, setStates] = React.useState<Array<{ name: string; key: number }>>([]);
  const [isFetchingStatesLoading, setIsFetchingStatesLoading] = React.useState(false);
  const formikProps = useFormikContext<FormikValues>();
  const selectedCountryId = prefix ? formikProps.values?.[prefix]?.countryId : formikProps.values?.countryId;
  const isCountryWithoutStates = selectedCountryId && states.length === 0 && !isFetchingStatesLoading;

  const countries = international
    ? validCountries?.map(iso => appContext.store.countries.find(c => c.iso === iso)) ||
      appContext.store.countries
    : null;

  const updateStates = (countryId: number) => {
    const country = appContext.store.countries.find(c => c.id === countryId);
    if (!country?.statesRequired) {
      setStates([]);
      return;
    }
    if (!countryId || countryId === 214) {
      setStates(appContext.store.states.map(elem => ({ name: `${elem.abbr} - ${elem.name}`, key: elem.id })));
    } else {
      setIsFetchingStatesLoading(true);
      getStates(countryId).then(res => {
        if (res.status === Status.Ok) {
          setStates(res.payload.states.map(elem => ({ name: `${elem.abbr} - ${elem.name}`, key: elem.id })));
        }
      });
      setIsFetchingStatesLoading(false);
    }
  };

  React.useEffect(() => {
    updateStates(address?.countryId || 214);
  }, [address]);

  const cityField = (
    <FormikFieldWrapper
      inPlaceError
      size={appContext.store.admin ? 'xs' : undefined}
      name={appendName(prefix, 'city')}
      componentType="input"
      disabled={disabled}
      labelComponent="City"
      autoComplete="address-level2"
      required
      data-cy={appContext.store.admin ? appendName(prefix, 'addressForm.cityInput') : 'addressForm.cityInput'}
    />
  );

  const stateField = (
    <FormikFieldWrapper
      autoSelect
      size={appContext.store.admin ? 'xs' : undefined}
      name={appendName(prefix, 'stateId')}
      componentType="combobox"
      withClear
      labelComponent={international ? 'State / Province' : 'State'}
      options={states}
      inPlaceError
      disabled={disabled}
      required
      data-cy={
        appContext.store.admin ? appendName(prefix, 'addressForm.stateInput') : 'addressForm.stateInput'
      }
    />
  );

  return (
    <div className={cn('p-0 md:mt-16', props.className)}>
      {addressFormTitle}
      {address && !newAddress ? (
        <div className="flex flex-col md:flex-row items-center justify-between">
          <AddressDescription address={address} />
          <div className="paragraph-mobile items-center text-gray-500 mx-4 my-2">or</div>
          <Button
            size="md"
            className="-md:w-full flex items-center rounded-lg box-border !border !border-dashed !border-gray-300 !px-6 !py-2"
            color="dark"
            outline
            onClick={() => setNewAddress()}
            type="button"
            data-cy={
              appContext.store.admin
                ? appendName(prefix, 'addressForm.newAddressBtn')
                : 'addressForm.newAddressBtn'
            }
          >
            <p className="mr-[5px] text-2xl text-blue">+</p>
            <p className="ml-1 text-sm">New Address</p>
          </Button>
        </div>
      ) : (
        <>
          <FormikFieldWrapper
            inPlaceError
            size={appContext.store.admin ? 'xs' : undefined}
            name={appendName(prefix, 'name')}
            componentType="input"
            disabled={disabled}
            labelComponent="Name"
            autoComplete="name"
            required
            data-cy={
              appContext.store.admin ? appendName(prefix, 'addressForm.nameInput') : 'addressForm.nameInput'
            }
          />
          <FormikFieldWrapper
            inPlaceError
            size={appContext.store.admin ? 'xs' : undefined}
            name={appendName(prefix, 'company')}
            componentType="input"
            disabled={disabled}
            autoComplete="organization"
            labelComponent="Company"
            data-cy={
              appContext.store.admin
                ? appendName(prefix, 'addressForm.companyInput')
                : 'addressForm.companyInput'
            }
          />
          <TwoColumnFormField
            leftFieldClassName="!pl-0 -md:!pr-0"
            rightFieldClassName="!pr-0 -md:!pl-0"
            className={`p-0 ${appContext.store.admin ? 'md:mx-0' : ''}`}
          >
            <FormikFieldWrapper
              inPlaceError
              size={appContext.store.admin ? 'xs' : undefined}
              name={appendName(prefix, 'address1')}
              componentType="input"
              disabled={disabled}
              labelComponent="Address 01"
              autoComplete="address-line1"
              required
              data-cy={
                appContext.store.admin
                  ? appendName(prefix, 'addressForm.address1Input')
                  : 'addressForm.address1Input'
              }
            />
            <FormikFieldWrapper
              inPlaceError={false}
              size={appContext.store.admin ? 'xs' : undefined}
              name={appendName(prefix, 'address2')}
              componentType="input"
              disabled={disabled}
              labelComponent="Address 02"
              autoComplete="address-line2"
              secondaryMessage={
                !!poBoxWarning && (
                  <p className="m-0 p-0 text-xs leading-4">Sorry, we cannot ship to PO boxes</p>
                )
              }
              data-cy={
                appContext.store.admin
                  ? appendName(prefix, 'addressForm.address2Input')
                  : 'addressForm.address2Input'
              }
            />
          </TwoColumnFormField>
          {countries ? (
            <FormikFieldWrapper
              size={appContext.store.admin ? 'xs' : undefined}
              name={appendName(prefix, 'countryId')}
              componentType="combobox"
              labelComponent="Country"
              options={countries.map(elem => ({ name: elem.name, key: elem.id }))}
              inPlaceError
              disabled={disabled}
              required
              onChange={(value: OptionType<number>) => {
                formikProps.setFieldValue(appendName(prefix, 'stateId'), null);
                updateStates(value?.key);
              }}
              data-cy={
                appContext.store.admin
                  ? appendName(prefix, 'addressForm.countryInput')
                  : 'addressForm.countryInput'
              }
            />
          ) : (
            <Field type="hidden" name={appendName(prefix, 'countryId')} value={214} autoComplete="off" />
          )}
          {isCountryWithoutStates ? (
            <div className="md:!mt-1">{cityField}</div>
          ) : (
            <TwoColumnFormField
              leftFieldClassName="!pl-0 -md:!pr-0"
              rightFieldClassName="!pr-0 -md:!pl-0"
              className={`p-0 ${appContext.store.admin ? 'md:mx-0' : ''}`}
            >
              {cityField}
              {stateField}
            </TwoColumnFormField>
          )}
          <TwoColumnFormField
            leftFieldClassName="!pl-0 -md:!pr-0"
            rightFieldClassName="!pr-0 -md:!pl-0"
            className={`p-0 md:!mt-1 ${appContext.store.admin ? 'md:mx-0' : ''}`}
          >
            <FormikFieldWrapper
              inPlaceError
              size={appContext.store.admin ? 'xs' : undefined}
              name={appendName(prefix, 'zipcode')}
              componentType="input"
              disabled={disabled}
              secondaryMessage={
                !!zipWarning && (
                  <p className="m-0 p-0 font-hvThin text-xs leading-4 text-gray-500">
                    Changing your zipcode may change your final price
                  </p>
                )
              }
              labelComponent="ZIP code"
              autoComplete="postal-code"
              required
              data-cy={
                appContext.store.admin
                  ? appendName(prefix, 'addressForm.zipcodeInput')
                  : 'addressForm.zipcodeInput'
              }
            />
            <FormikFieldWrapper
              inPlaceError
              size={appContext.store.admin ? 'xs' : undefined}
              name={appendName(prefix, 'phone')}
              componentType="input"
              disabled={disabled}
              labelComponent="Phone Number"
              autoComplete="tel-national"
              required
              data-cy={
                appContext.store.admin
                  ? appendName(prefix, 'addressForm.phoneInput')
                  : 'addressForm.phoneInput'
              }
            />
          </TwoColumnFormField>
        </>
      )}
    </div>
  );
};

export default AddressForm;
