import * as React from 'react';
import cn from 'classnames';
import { Link as RouterLink } from 'react-router-dom';
import { Tab, Transition, PopoverButton, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import { Fragment } from 'react';
import { Taxon, Taxonomy } from 'contexts/AppContext/types';
import AppContext from 'contexts/AppContext/AppContext';
import { useHoverContext } from '../HoverProvider/HoverProvider';

interface Props {
  items: Taxon[];
  taxon1: Taxon;
  taxon2: Taxon;
  onItemClick: () => void;
}

const NestedPanel = ({ items, onItemClick }: Props) => {
  const getTextClasses = (selected: boolean, selectedIndex: number): string => {
    let classes = '';
    if (selectedIndex === 0) {
      classes = 'text-default hover:text-gray-300';
    } else if (selected) {
      classes = 'text-default';
    } else {
      classes = 'text-gray-300 hover:text-default';
    }
    return classes;
  };

  const data = [
    {
      name: 'Shipping Boxes',
      tagline:
        'Elevate your brand with custom printed shipping boxes! Print full-color on kraft or white boxes with no minimums and fast turnarounds.',
      action: 'Shop Now',
      link: '/boxes/shipping-boxes',
    },
    {
      name: 'Rush Critical Overnight Printing',
      tagline: 'Get your prints within 24 hours. Next day and same day printing services on most products.',
      action: 'Get A Quote',
      link: '/rush-critical',
    },
  ];
  const appContext = React.useContext(AppContext);
  const nestedTaxonomy: Taxonomy = appContext.store.taxonomies.find(elem => elem.name === 'Nested');

  const { hoveredItem, setHoveredItem } = useHoverContext();
  const selectedIndex = hoveredItem !== null ? hoveredItem + 1 : 0;

  return (
    <>
      <TabGroup
        vertical
        as="div"
        className="relative flex w-1/2 flex-row font-hvMedium"
        selectedIndex={selectedIndex}
        onMouseLeave={() => setHoveredItem(null)}
      >
        {() => (
          <>
            <TabList className="flex w-1/2 flex-col py-2">
              <Tab className="hidden" />
              {items.map((category: Taxon, i: number) => (
                <Tab as={Fragment} key={`${category.name}-${i}`}>
                  {({ selected }) => (
                    <div
                      onMouseEnter={() => setHoveredItem(i)}
                      key={`${category.name}-${i}`}
                      className={cn(
                        selected
                          ? 'z-10 -mr-px border-solid border-gray-300 ' +
                              'border-b border-l border-r-0 border-t ' +
                              'bg-gray-100'
                          : 'border-b border-l border-t border-transparent',
                        'cursor-pointer p-2 transition duration-300 ease-in-out',
                        getTextClasses(selected, selectedIndex),
                      )}
                    >
                      {category.name}
                    </div>
                  )}
                </Tab>
              ))}
              <div className="mr-2 mt-2 cursor-pointer border-t border-gray-300 px-2 pt-4 text-default transition duration-300 ease-in-out hover:text-gray-300">
                <PopoverButton
                  as={RouterLink}
                  to="/products"
                  className={cn(
                    'cursor-pointer no-underline transition duration-300 ease-in-out',
                    getTextClasses(false, selectedIndex),
                  )}
                  onClick={onItemClick}
                >
                  Shop All Products
                </PopoverButton>
              </div>
            </TabList>
            <TabPanels
              className={cn(
                selectedIndex !== 0 ? 'border-l border-solid border-gray-300 bg-gray-100' : null,
                'w-1/2 p-2',
              )}
            >
              <TabPanel />
              {items.map(category => (
                <TabPanel key={category.name}>
                  {({ selected }) => (
                    <Transition
                      as="div"
                      show={selected}
                      enter="transform transition duration-500"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="transform transition duration-500"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      {category.taxons.map((item: Taxon, i: number) => (
                        <div key={`${item.name}-${i}`} className="p-2 leading-6">
                          <PopoverButton
                            as={RouterLink}
                            to={item.pagePath}
                            className="cursor-pointer truncate p-2
                          text-default no-underline transition duration-300 ease-in-out hover:text-gray-300 lg:text-sm"
                            onClick={onItemClick}
                          >
                            {item.name}
                          </PopoverButton>
                        </div>
                      ))}
                      {category.name === 'Boxes' && (
                        <div className="p-2 leading-6">
                          <PopoverButton
                            as={RouterLink}
                            to="/box-design"
                            className="cursor-pointer truncate p-2
                            text-default no-underline transition duration-300 ease-in-out hover:text-gray-300 lg:text-sm"
                            onClick={onItemClick}
                          >
                            Box Design Services
                          </PopoverButton>
                        </div>
                      )}
                      <div key={`${category.name}`} className="border-t border-gray-300 p-2 leading-6">
                        {/*  Horrible hack - remove */}
                        {category.name === 'Books & Booklets' ? (
                          <>
                            <div className="pb-2 leading-6">
                              <PopoverButton
                                as={RouterLink}
                                to="/booklets"
                                className="cursor-pointer truncate p-2
                            text-default no-underline transition duration-300 ease-in-out hover:text-gray-300 lg:text-sm"
                                onClick={onItemClick}
                              >
                                Shop All Booklets
                              </PopoverButton>
                            </div>
                            <div className="py-2 leading-6">
                              <PopoverButton
                                as={RouterLink}
                                to="/books"
                                className="cursor-pointer truncate p-2
                          text-default no-underline transition duration-300 ease-in-out hover:text-gray-300 lg:text-sm"
                                onClick={onItemClick}
                              >
                                Shop All Books
                              </PopoverButton>
                            </div>
                          </>
                        ) : (
                          <PopoverButton
                            as={RouterLink}
                            to={category.pagePath}
                            className="cursor-pointer truncate p-2
                          text-default no-underline transition duration-300 ease-in-out hover:text-gray-300 lg:text-sm"
                            onClick={onItemClick}
                          >
                            Shop All {category.name}
                          </PopoverButton>
                        )}
                      </div>
                    </Transition>
                  )}
                </TabPanel>
              ))}
            </TabPanels>
          </>
        )}
      </TabGroup>
      <div className="relative ml-2 w-1/2">
        <div className="grid h-full w-full grid-cols-2 gap-x-4 px-2 py-4 xl:gap-x-8">
          {nestedTaxonomy.taxons.map((file: Taxon, i: number) => (
            <PopoverButton
              as={RouterLink}
              to={
                data.find(elem => elem.name === file.name) !== undefined
                  ? data.find(elem => elem.name === file.name).link
                  : file.pagePath
              }
              className="group cursor-pointer p-1 text-default
                     no-underline transition duration-300 ease-in-out hover:shadow-md group-hover:text-gray-300"
              onClick={onItemClick}
              key={`${file.name}-${i}`}
            >
              <>
                <div className="overflow-hidden rounded-lg bg-gray-100">
                  <img
                    src={file.image.url}
                    alt={file.name}
                    className="pointer-events-none aspect-[10/7] w-full transform
                                 object-cover text-default transition duration-300 ease-in-out group-hover:scale-110 group-hover:opacity-80"
                  />
                </div>
                <div
                  className="mt-2 block font-hvBold
                        text-sm text-default transition duration-300 ease-in-out group-hover:text-gray-300"
                >
                  {file.name}
                </div>
                {nestedTaxonomy.taxons.length <= 3 && (
                  <>
                    <div className="mt-2 block text-left text-sm text-default text-wrap">
                      {data.find(elem => elem.name === file.name).tagline}
                    </div>
                    <div
                      className="mt-2 block text-left font-hvBold text-sm
                                 text-blue transition duration-300 ease-in-out group-hover:underline"
                    >
                      {data.find(elem => elem.name === file.name).action}
                    </div>
                  </>
                )}
              </>
            </PopoverButton>
          ))}
        </div>
      </div>
    </>
  );
};

export default NestedPanel;
