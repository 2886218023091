import SimplyShip from 'bundles/App/pages/CartPage/MiddleSection/SimplyShip/SimplyShip';
import * as React from 'react';
import Button from 'styleguide/components/Button/Button';
import ProductsTable from 'styleguide/components/ProductsTable/ProductsTable';
import OrderContext from 'contexts/OrderContext/OrderContext';
import SimplyShipContextContainer from 'app/contexts/SimplyShipContext/SimplyShipContextContainer';
import TempOrderTotalsContext from 'contexts/TempOrderTotalsContext/TempOrderTotalsContext';
import { H5 } from 'styleguide/components/Heading';
import Collapse from 'styleguide/components/Collapse/Collapse';
import ShippingCost from './ShippingCost';
import DateTimeZone from 'styleguide/components/DateTimeZone/DateTimeZone';
import { Shipment } from 'api/orders/types';

const DeliveryStepEdit = () => {
  const { order } = React.useContext(OrderContext);
  const tempOrderTotalsContext = React.useContext(TempOrderTotalsContext);
  const getLineItemsFromShipment = (ship: Shipment) => {
    const lineItemIds = ship.inventoryUnits?.map(i => i.lineItemId);
    return order.lineItems.filter(li => lineItemIds.includes(li.id));
  };

  return (
    <>
      <div className="rounded-lg px-6 py-3 shadow-md mb-5">
        <H5>Simply Ship Cost Estimator</H5>
        <div className="paragraph-light-mobile max-w-screen-sm pt-6 leading-5">
          We automatically calculate the best price for you based on production times and shipping speeds. All
          you need to do is pick your delivery date!
        </div>
      </div>
      {order.shipments.length > 1
        ? order.shipments.map((shipment, index) => (
            <Collapse className="p-5 rounded-lg border border-gray-200 mb-5" key={shipment.id}>
              <Collapse.Item
                title={
                  <div className="w-full">
                    <div className="font-hvMedium text-lg mr-2 flex justify-between items-center">
                      <span className="font-hvBold">{`Shipment ${index + 1}`}</span>
                      <div className="mr-4 text-gray-600 text-base">
                        {getLineItemsFromShipment(shipment).length}{' '}
                        {getLineItemsFromShipment(shipment).length === 1 ? 'item' : 'items'} -{' '}
                        <ShippingCost shipment={shipment} />
                      </div>
                    </div>
                    {!order.shipments[0] ||
                      (order.shipments[0] && order.shipments[0].quoteState === 'valid' && (
                        <div className="text-sm text-gray-600 inline-flex items-center gap-2">
                          Delivered by{' '}
                          <strong data-cy="deliveryDate">
                            <DateTimeZone
                              date={
                                tempOrderTotalsContext?.orderTotals?.shipment?.deliveryDate ||
                                shipment?.deliveryDate
                              }
                              key={shipment.id}
                              className="mb-1 mt-1 block text-sm !leading-5 -sm:text-xs"
                              fnsFormat="MMM do yyyy h:mma"
                              showEndOfDay
                            />
                          </strong>
                        </div>
                      ))}
                  </div>
                }
                classNameBody="!bg-shades-0 !pt-0 !px-0 overflow-hidden"
                isWide
                withPadding
                iconClassName="!h-3 !w-3 flex"
                initiallyExpanded={index === 0}
                body={
                  <SimplyShipContextContainer
                    admin={false}
                    save
                    shipment={shipment}
                    setOrderTotals={tempOrderTotalsContext.setOrderTotals}
                  >
                    <SimplyShip checkoutPageMode disableInput />
                    <ProductsTable shipment={shipment} isLoading={order.status === 'loading'} checkoutMode />
                  </SimplyShipContextContainer>
                }
              />
            </Collapse>
          ))
        : order.shipments.map(shipment => (
            <SimplyShipContextContainer
              admin={false}
              save
              shipment={shipment}
              setOrderTotals={tempOrderTotalsContext.setOrderTotals}
            >
              <strong>Shipping Cost:</strong> <ShippingCost shipment={shipment} />
              <SimplyShip checkoutPageMode disableInput />
              <ProductsTable shipment={shipment} isLoading={order.status === 'loading'} checkoutMode />
            </SimplyShipContextContainer>
          ))}
      <Button
        data-cy="deliveryStepBtn"
        className="w-full !px-0 !font-hvMedium !text-base"
        type="submit"
        color="orange"
        disabled={
          order.status === 'loading' ||
          !(typeof order.status === 'string' || order.status instanceof String) ||
          order.shipments.some(ship => ship.quoteState === 'invalid')
        }
      >
        Add Payment
      </Button>
    </>
  );
};
export default DeliveryStepEdit;
