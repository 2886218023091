import * as React from 'react';
import { products, dimensions, paperType } from 'bundles/App/pages/ContactUsPage/formOptions';
import FieldWrapper from 'styleguide/components/Formik/FieldWrapper/FieldWrapper';
import { FormikValues, useFormikContext } from 'formik';
import { QuoteRequestFormType } from 'bundles/App/pages/ContactUsPage/types';
import Label from 'styleguide/components/Formik/Label/Label';
import RadioTabs from 'styleguide/components/Formik/RadioTabs';
import { FormikFieldWrapper } from 'app/styleguide/components/Formik';
import SmartyAutocomplete from 'app/styleguide/components/SmartyAutocomplete/SmartyAutocomplete';
import type { Address } from '../Account/Addresses/types';

const QuoteRequestForm = () => {
  const { values } = useFormikContext<QuoteRequestFormType>();
  const formikProps = useFormikContext<FormikValues>();
  return (
    <>
      {values.quoteRequest && (
        <>
          <FormikFieldWrapper
            data-cy="quoteRequest.product"
            name="quoteRequest.product"
            componentType="combobox"
            optionGroups={products}
            labelComponent="Product"
            inPlaceError
          />
          <FormikFieldWrapper
            data-cy="quoteRequest.dimension"
            name="quoteRequest.dimension"
            componentType="combobox"
            optionGroups={dimensions}
            labelComponent="Dimension"
            inPlaceError
          />
          <FormikFieldWrapper
            data-cy="quoteRequest.paperType"
            name="quoteRequest.paperType"
            componentType="combobox"
            optionGroups={paperType}
            labelComponent="Paper Type"
            inPlaceError
          />
          <FieldWrapper
            Label={<Label placement="left">Orientation</Label>}
            Input={
              <RadioTabs name="quoteRequest.orientation">
                <FormikFieldWrapper
                  dataCy="quoteRequest.orientation.portrait"
                  name="quoteRequest.orientation"
                  value="portrait"
                  labelComponent="Portrait"
                  componentType="radio"
                />
                <FormikFieldWrapper
                  dataCy="quoteRequest.orientation.landscape"
                  name="quoteRequest.orientation"
                  value="landscape"
                  labelComponent="Landscape"
                  componentType="radio"
                />
              </RadioTabs>
            }
          />
          <FormikFieldWrapper
            data-cy="quoteRequest.documentCount"
            name="quoteRequest.documentCount"
            componentType="input"
            labelComponent="Number of Documents"
            wrapperClassName="mt-5"
          />
          <FormikFieldWrapper
            data-cy="quoteRequest.pagesPerDocument"
            name="quoteRequest.pagesPerDocument"
            componentType="input"
            labelComponent="Pages per Document"
            wrapperClassName="mt-5"
          />
          <FieldWrapper
            className="!mt-5"
            Label={<Label placement="left">Number of Sides</Label>}
            Input={
              <RadioTabs name="quoteRequest.sides">
                <FormikFieldWrapper
                  dataCy="quoteRequest.sides.single"
                  name="quoteRequest.sides"
                  value="Single Sided"
                  labelComponent="Single Sided"
                  componentType="radio"
                />
                <FormikFieldWrapper
                  dataCy="quoteRequest.sides.double"
                  name="quoteRequest.sides"
                  value="Double Sided"
                  labelComponent="Double Sided"
                  componentType="radio"
                />
              </RadioTabs>
            }
          />
          <FieldWrapper
            className="!mt-5"
            Label={<Label placement="left">Color</Label>}
            Input={
              <RadioTabs name="quoteRequest.color">
                <FormikFieldWrapper
                  dataCy="quoteRequest.color.colored"
                  name="quoteRequest.color"
                  value="Color"
                  labelComponent="Color"
                  componentType="radio"
                />
                <FormikFieldWrapper
                  dataCy="quoteRequest.color.blackAndWhite"
                  name="quoteRequest.color"
                  value="Black and White"
                  labelComponent="Black and White"
                  componentType="radio"
                />
              </RadioTabs>
            }
          />
          <div data-cy="estimatorAddressInput">
            <SmartyAutocomplete
              setAddress={(params: Address) => {
                formikProps.setFieldValue('quoteRequest.address', params);
              }}
              controlClassName="mt-8"
            />
          </div>
        </>
      )}
      <FormikFieldWrapper
        data-cy="quoteRequest.comments"
        name="quoteRequest.comments"
        rows={5}
        componentType="textarea"
        labelComponent="Comments"
        wrapperClassName="!mt-5"
      />
    </>
  );
};

export default QuoteRequestForm;
