// extracted by mini-css-extract-plugin
var _1 = "UL__element__ICA8H";
var _2 = "UL__element--black__zMbka";
var _3 = "UL__element--blue__TDp_U";
var _4 = "UL__element--dark__iyuPj";
var _5 = "UL__element--medium__OAkPb";
var _6 = "UL__element--orange__rBgBS";
var _7 = "UL__element--small__bvOLk";
var _8 = "UL__element--white__FA2rJ";
var _9 = "UL__list__E5zUg";
export { _1 as "element", _2 as "element--black", _3 as "element--blue", _4 as "element--dark", _5 as "element--medium", _6 as "element--orange", _7 as "element--small", _8 as "element--white", _9 as "list" }
