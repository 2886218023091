import * as React from 'react';
import QuoteContext, { QuoteContextType, QuoteStatus } from './QuoteContext';
import { KeyVal } from 'libs/utils/common-types';
import { Formik, FormikProps, FormikValues } from 'formik';
import FormikEffect from 'styleguide/components/Formik/FormikEffect';
import { object, number, boolean } from 'yup';
import { Product as ProductType } from 'styleguide/components/QuoteEditForm/Product';
import { Status } from 'libs/utils/api/types';
import PersephoneContext from '../PersephoneContextContainer/PersephoneContext';
import merge from 'lodash-es/merge';
import camelCase from 'lodash-es/camelCase';
import kebabCase from 'lodash-es/kebabCase';
import { LineItem } from 'api/persephone/persephone';
import kebabcaseKeys from 'kebabcase-keys';
import { quoteKey } from 'app/constants';
import { CachedQuotes } from './types';
import { setLocalStorage, getLocalStorage, removeLocalStorage } from 'utils/LocalStorageService';
import { useLocation } from 'react-router-dom';
import queryString from 'qs';

interface Props {
  product?: ProductType;
  alias: string;
  productSlug: string;
  initialValues?: KeyVal;
  initialPrice?: number | null;
  productionDays: number;
  discountPercent?: number;
  discountMessage?: string;
  children?: React.ReactNode;
  onChange: (lineItem: LineItem) => void;
  onSubmit: (values: KeyVal, price: number) => void;
  onCustomize?: () => void;
  canCustomize?: boolean;
  displayNotes: boolean;
  showHiddenOptions: boolean;
  instantQuote: boolean;
  cacheQuotes?: boolean;
}

const quoteFormSchema = object().shape({
  'document-count': number()
    .typeError('Quantity must be a number')
    .positive('Quantity must be greater than zero')
    .integer('Quantity must be an integer')
    .required('Quantity is required'),
  'document-page-count': number()
    .positive('Quantity must be greater than zero')
    .typeError('Quantity must be a number')
    .integer('Quantity must be an integer')
    .required('Quantity is required'),
  'disable-validations': boolean(),
});

const QuoteContextContainer: React.FunctionComponent<Props> = ({
  alias,
  productSlug,
  productionDays,
  discountPercent,
  children,
  initialPrice,
  instantQuote,
  cacheQuotes,
  ...props
}: Props) => {
  const location = useLocation();
  // this is used in this file code line "formRef = instance;"
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let formRef = React.createRef<FormikProps<FormikValues>>();
  const { catalog, doQuoteRequest } = React.useContext(PersephoneContext);
  const [t, setT] = React.useState<NodeJS.Timeout>(null);
  const [errorMessage, setErrorMessage] = React.useState<string>(null);
  const [price, setPrice] = React.useState<number>(null);
  const [discountPrice, setDiscountPrice] = React.useState<number>(null);
  const [cost, setCost] = React.useState<number>(null);
  const [weight, setWeight] = React.useState<number>(null);
  // if catalog is loaded, fetch product and initialValues on render
  let newProduct: ProductType = catalog ? new ProductType(catalog[camelCase(productSlug)]) : null;
  const [product, setProduct] = React.useState<ProductType>(newProduct);
  const [mounted, setMounted] = React.useState<boolean>(false);
  const [initialLoad, setInitialLoad] = React.useState<boolean>(true);

  const addQuoteToQueryParams = React.useCallback(
    (values: KeyVal) => {
      if (!initialLoad) {
        const valuesWithPrefix = Object.keys(values).reduce((acc, key) => {
          acc[`quote_${key}`] = values[key];
          return acc;
        }, {} as KeyVal);
        const quote = { product: productSlug, ...valuesWithPrefix };
        const newUrl = `${window.location.pathname}?${queryString.stringify(quote)}`;
        window.history.replaceState({ path: newUrl }, '', newUrl);
      }
    },
    [productSlug, initialLoad],
  );

  React.useEffect(() => {
    setInitialLoad(true);
  }, [location]);

  const removeQueryParams = () => {
    const newUrl = window.location.pathname;
    window.history.replaceState({ path: newUrl }, '', newUrl);
  };

  const getInitialValues = (cachedValues: KeyVal) => {
    if (catalog) {
      if (props.initialValues && !mounted) {
        setMounted(true);
        return kebabcaseKeys(props.initialValues);
      }
      if (cachedValues) {
        return cachedValues;
      }
      return newProduct.getKeyedObjectWithDefaultValues(alias);
    }
    return null;
  };

  const [initialValues, setInitialValues] = React.useState<KeyVal>(
    props.initialValues ? kebabcaseKeys(props.initialValues) : null,
  );
  const [status, setStatus] = React.useState<QuoteStatus>(product ? 'ready' : 'productLoading');

  const onQuoteRequest = (values: KeyVal): Promise<number> =>
    new Promise(resolve => {
      setStatus('quoting');
      setErrorMessage(null);
      setPrice(null);
      setDiscountPrice(null);
      doQuoteRequest(merge(values, { product: kebabCase(productSlug) }), productionDays).then(res => {
        if (res.status === Status.Ok) {
          const { lineItem } = res.payload;
          setStatus('ready');
          setErrorMessage(null);
          const newPrice = parseFloat(lineItem.total);
          setPrice(newPrice);
          if (cacheQuotes) {
            setLocalStorage(quoteKey, {
              ...(getLocalStorage(quoteKey) as CachedQuotes),
              [`${productSlug}_${location.pathname}`]: values,
            });
          }
          if (cacheQuotes) {
            addQuoteToQueryParams(values);
          }
          if (discountPercent) {
            const discount = Math.min(newPrice * (discountPercent / 100.0), 500.0);
            setDiscountPrice(newPrice - discount);
          }
          setCost(parseFloat(lineItem.cost));
          setWeight(parseFloat(lineItem.weight));
          props.onChange({ lineItem });
          resolve(newPrice);
        } else if (res.status === Status.ClientError) {
          if (res.payload.data.type === 'CustomQuoteError') {
            setStatus('customQuote');
          } else if (res.payload.data.type === 'minRequirementsError') {
            setStatus('minRequirementsError');
            const newPrice = parseFloat(res.payload.data.total.toString());
            setPrice(newPrice);
            setErrorMessage(res.payload.message);
          } else {
            setStatus('quoteError');
            setErrorMessage(res.payload.message);
          }
          if (cacheQuotes) {
            removeQueryParams();
          }
          resolve(null);
        } else {
          if (cacheQuotes) {
            removeQueryParams();
          }
          setStatus('quoteError');
          setErrorMessage('Something went wrong when receiving a response from the server.');
          resolve(null);
        }
      });
    });

  // prevent quote request if user is filling out project name. debounced 500ms
  const onChange = React.useCallback(
    (prevValues: KeyVal, values: KeyVal, force = false): Promise<number> =>
      new Promise(resolve => {
        if ((values && !prevValues) || (prevValues && prevValues.name === values.name) || force) {
          if (t) {
            global.clearTimeout(t);
          }
          setT(global.setTimeout(() => onQuoteRequest(values).then(res => resolve(res)), 500));
        }
      }),
    [onQuoteRequest, t],
  );

  // If catalog hasnt been loaded yet, waits for catalog to load.
  // fetches product and initialValues if the product has changed.
  React.useEffect(() => {
    if (catalog) {
      setStatus('productLoading');

      if (!product || (product && productSlug !== product.getProductKey())) {
        newProduct = new ProductType(catalog[camelCase(productSlug)]);
        setProduct(newProduct);
      } else {
        newProduct = product;
      }

      const cachedValues: KeyVal = getLocalStorage(quoteKey)?.[`${productSlug}_${location.pathname}`];
      const formValues: KeyVal = getInitialValues(cachedValues);

      setInitialValues(formValues);
      onChange(null, formValues, true).then();
      setStatus('ready');
      setInitialLoad(false);
    }
  }, [productSlug, catalog]);

  const onSubmit = (values: KeyVal) => {
    setStatus('addingToCart');
    if (cacheQuotes) {
      removeLocalStorage(quoteKey);
    }
    props.onSubmit(values, price);
  };

  const onCustomize = () => {
    setStatus('quoting');
    props.onCustomize();
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const payload: QuoteContextType = {
    product,
    alias,
    initialValues,
    initialPrice,
    errorMessage,
    price,
    cost,
    weight,
    status,
    onChange,
    onSubmit,
    onCustomize,
    discountPrice,
    discountMessage: props.discountMessage,
    displayNotes: props.displayNotes,
    canCustomize: props.canCustomize,
    showHiddenOptions: props.showHiddenOptions,
    instantQuote,
  };

  return (
    <QuoteContext.Provider value={payload}>
      <Formik
        initialValues={initialValues}
        onSubmit={values => onSubmit(values)}
        validationSchema={quoteFormSchema}
        enableReinitialize
        innerRef={instance => {
          // @ts-ignore
          formRef = instance;
        }}
      >
        <>
          {instantQuote ? (
            <FormikEffect onChange={onChange} />
          ) : (
            <FormikEffect
              onChange={(prevValues: KeyVal, values: KeyVal) => {
                let tabValuesSame = true;

                if (
                  !!prevValues &&
                  !!values &&
                  values['tab-dividers'] &&
                  values['tab-dividers'] !== 'no-tab-dividers'
                ) {
                  const numberOfTabs = values['number-of-tabs'];
                  const tabArray = Array.from(
                    { length: parseInt(numberOfTabs, 10) },
                    (_, index) => index + 1,
                  );

                  tabValuesSame = tabArray.every(
                    index =>
                      prevValues[`tab-${index}-name`] === values[`tab-${index}-name`] &&
                      prevValues[`tab-${index}-page`] === values[`tab-${index}-page`],
                  );
                }

                if (!!prevValues && !!values && prevValues.name === values.name && tabValuesSame) {
                  setPrice(null);
                }
              }}
            />
          )}
          {children}
        </>
      </Formik>
    </QuoteContext.Provider>
  );
};

export default QuoteContextContainer;
