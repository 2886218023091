// extracted by mini-css-extract-plugin
var _1 = "ProofApprovalsPage__active__DelhY";
var _2 = "ProofApprovalsPage__approved__AI6k8";
var _3 = "ProofApprovalsPage__bodyContainer__FJDqj";
var _4 = "ProofApprovalsPage__bodyText__o3aHO";
var _5 = "ProofApprovalsPage__button__XQWV3";
var _6 = "ProofApprovalsPage__buttonContainer__AI43e";
var _7 = "ProofApprovalsPage__columnContainer__jSc9F";
var _8 = "ProofApprovalsPage__commentsLabel__RarKq";
var _9 = "ProofApprovalsPage__contactContainer__cUjsQ";
var _a = "ProofApprovalsPage__expired__xOJrB";
var _b = "ProofApprovalsPage__fileConcern__S_3EN";
var _c = "ProofApprovalsPage__fileConcernForm__ZI33M";
var _d = "ProofApprovalsPage__headerText__oqmZd";
var _e = "ProofApprovalsPage__itemSpacing__RG9_L";
var _f = "ProofApprovalsPage__loaderContainer__WkRaP";
var _10 = "ProofApprovalsPage__modalButton__Bvy_X";
var _11 = "ProofApprovalsPage__modalContainer__XH6pl";
var _12 = "ProofApprovalsPage__modalContent__KrMjh";
var _13 = "ProofApprovalsPage__modalHeading__XU7L1";
var _14 = "ProofApprovalsPage__rejected__Ui05B";
var _15 = "ProofApprovalsPage__sectionBodySeparator__qeXFo";
var _16 = "ProofApprovalsPage__sectionColumn__k207t";
var _17 = "ProofApprovalsPage__sectionHeader__VMSLF";
var _18 = "ProofApprovalsPage__sectionSeparator__AmSTs";
var _19 = "ProofApprovalsPage__tabs__untqW";
var _1a = "ProofApprovalsPage__title__D7PMk";
var _1b = "ProofApprovalsPage__titleContainer__SeYj3";
var _1c = "ProofApprovalsPage__warning__X2wBV";
export { _1 as "active", _2 as "approved", _3 as "bodyContainer", _4 as "bodyText", _5 as "button", _6 as "buttonContainer", _7 as "columnContainer", _8 as "commentsLabel", _9 as "contactContainer", _a as "expired", _b as "fileConcern", _c as "fileConcernForm", _d as "headerText", _e as "itemSpacing", _f as "loaderContainer", _10 as "modalButton", _11 as "modalContainer", _12 as "modalContent", _13 as "modalHeading", _14 as "rejected", _15 as "sectionBodySeparator", _16 as "sectionColumn", _17 as "sectionHeader", _18 as "sectionSeparator", _19 as "tabs", _1a as "title", _1b as "titleContainer", _1c as "warning" }
