import React from 'react';
import { IconArrowLeft, IconArrowRight } from 'styleguide/icons';

type CarouselCardArrowsProps = {
  goBack: () => void;
  goForward: () => void;
};

const CarouselCardArrows = ({ goBack, goForward }: CarouselCardArrowsProps) => (
  <div className="absolute top-1/2 w-full transform -translate-y-1/2">
    <div className="flex justify-between">
      <div className="flex items-center py-2 cursor-pointer">
        <IconArrowLeft onClick={goBack} size="xxl" color="blue" />
      </div>
      <div className="flex items-center py-2 cursor-pointer ">
        <IconArrowRight onClick={goForward} size="xxl" color="blue" />
      </div>
    </div>
  </div>
);

export default CarouselCardArrows;
