import * as React from 'react';
import { useLocation } from 'react-router-dom';
import Span from 'styleguide/components/Span/Span';
import ButtonAsNativeLink from 'styleguide/components/Button/ButtonAsNativeLink';
import { clickSignUpPdp } from 'api/gtm';
import { isProductPage } from 'libs/utils/pageUtils';
import AppContext from 'contexts/AppContext/AppContext';
import LazyLoad from 'react-lazyload';
import LoaderWrapper from 'styleguide/components/LoaderWrapper/LoaderWrapper';
import { Tooltip } from 'styleguide/components/Tooltip/Tooltip';
import ToolTipContent from 'styleguide/components/DiscountTooltipContent/DiscountTooltipContent';

const SubscriptionForm = () => {
  const appContext = React.useContext(AppContext);
  const location = useLocation();

  return (
    <LazyLoad once placeholder={LoaderWrapper('h-[405px] flex items-center justify-center')}>
      <div
        className="w-full border border-solid border-gray-50 p-4 mt-10 flex flex-col justify-center
                   items-center gap-4 md:flex-row"
      >
        <div
          className="flex flex-col items-center justify-center !text-base max-w-full md:items-start
                     md:!text-lg sm:min-w-[345px]"
        >
          <Span className="!mb-2 !font-hvBold !text-xl text-center">Get up to 20% off your first order.</Span>
          <div className="flex items-center mb-2">
            <Span className="max-w-xs !font-hvRoman !text-base -md:text-center">
              We&apos;ll send info on exclusive promotions, product launches, and much more.
            </Span>
          </div>
          <Tooltip
            placement="bottom"
            content={<ToolTipContent />}
            withArrow
            className="!z-100 sm:w-max max-w-[500px] rounded-lg bg-shades-0 px-5 py-4 text-sm shadow-xl !text-default"
            action="click"
            renderOpener={tooltipProps => (
              <div {...tooltipProps} className="text-xs font-hvBold cursor-pointer">
                See rules and conditions
              </div>
            )}
          />
        </div>
        <div className="flex items-center justify-center">
          <ButtonAsNativeLink
            buttonType="link"
            target={{
              pathname: '/sign-up',
              state: { from: location.pathname },
            }}
            color="orange"
            className="!font-hvMedium !text-sm -lg:mt-[0.5em]"
            onClick={() => {
              if (isProductPage(location.pathname, appContext.store.cmsPages)) {
                clickSignUpPdp();
              }
            }}
          >
            Sign Up - Get up to 20% Off
          </ButtonAsNativeLink>
        </div>
      </div>
    </LazyLoad>
  );
};

export default SubscriptionForm;
