import * as React from 'react';
import OrderContext from './OrderContext';
import { Order } from 'api/orders/types';
import { orderReducer } from './reducer';
import { getCurrentOrder } from './actions';
import { Helmet } from 'react-helmet-async';
import AppContext from 'contexts/AppContext/AppContext';
import { useLocation } from 'react-router-dom';

interface Props {
  order?: Order;
  updateOrder?: (order: Order) => void;
  fetchIfBlank?: boolean;
  forStorybook?: boolean;
  children: React.ReactNode;
}

const OrderContextContainer: React.FunctionComponent<Props> = ({ fetchIfBlank = true, ...props }) => {
  const appContext = React.useContext(AppContext);
  const [order, dispatch] = React.useReducer(orderReducer, props.order);
  const location = useLocation();

  React.useEffect(() => {
    if (!props.order && fetchIfBlank) {
      getCurrentOrder(dispatch, location.pathname);
    }
  }, []);

  React.useEffect(() => {
    if (props.forStorybook && props.order && props.order !== order) {
      dispatch({ type: 'updateOrder', payload: props.order });
    }
  }, [props.order]);

  React.useEffect(() => {
    if (props.updateOrder) {
      props.updateOrder(order);
    }
  }, [order]);

  const contextValue = React.useMemo(
    () => ({
      order,
      dispatch,
    }),
    [order, dispatch],
  );

  return (
    <OrderContext.Provider key={props.order ? props.order.number : null} value={contextValue}>
      {order &&
        appContext.store.admin &&
        (props.forStorybook ? (
          <title>{`yV - ${order.number} - ${order.billingAddress?.name}`}</title>
        ) : (
          <Helmet>
            <title>{`yV - ${order.number} - ${order.billingAddress?.name}`}</title>
          </Helmet>
        ))}
      {props.children}
    </OrderContext.Provider>
  );
};

export default OrderContextContainer;
