import * as React from 'react';
import cn from 'classnames';
import * as css from './Heading.scss';

const H6 = ({ children, className, ...otherProps }: React.HTMLProps<HTMLHeadingElement>) => (
  <h6 className={cn(css.Heading, css.H6, className)} {...otherProps}>
    {children}
  </h6>
);

export default H6;
