import React from 'react';
import { Carousel } from 'nuka-carousel';
import CarouselArrows from './CarouselArrows/CarouselArrows';

type MultiItemCarouselProps = {
  children: React.ReactNode;
  scrollDistance: number;
  autoplay?: boolean;
  autoplaySlideMs?: number;
  autoplaySuspensionMs?: number;
};

const MultiItemCarousel = ({
  scrollDistance,
  children,
  autoplay = true,
  autoplaySlideMs,
  autoplaySuspensionMs,
}: MultiItemCarouselProps) => (
  <div className="relative mx-auto max-w-7xl px-3">
    <Carousel
      className="mx-auto"
      wrapMode="wrap"
      showDots={false}
      showArrows
      arrows={
        <CarouselArrows
          mode="bottom"
          autoplay={autoplay}
          autoplaySlideMs={autoplaySlideMs}
          autoplaySuspensionMs={autoplaySuspensionMs}
        />
      }
      scrollDistance={scrollDistance}
      swiping
    >
      {children}
    </Carousel>
  </div>
);

export default MultiItemCarousel;
