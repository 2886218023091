import * as React from 'react';
import * as css from './ProofApprovalsPage.scss';
import Grid from 'styleguide/components/Grid/Grid';
import Span from 'styleguide/components/Span/Span';
import { contactPhone } from 'app/constants';
import { Reservation } from 'api/admin/orders/types';

export interface Props {
  reservation: Reservation;
}

const CustomerServiceContact = ({ reservation }: Props) => (
  <Grid.Row className={css.contactContainer}>
    <Grid.Col>
      <Span>Name: {reservation.name}</Span>
      <br />
      <Span>Email: {reservation.email}</Span>
      <br />
      <Span>
        Phone Number: {contactPhone} ext {reservation.extension}
      </Span>
    </Grid.Col>
  </Grid.Row>
);

export default CustomerServiceContact;
