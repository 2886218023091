import * as React from 'react';
import AppContext from 'contexts/AppContext/AppContext';
import { format } from 'date-fns-tz';

interface Props {
  date: string | Date;
  className?: string;
  fnsFormat: string;
  preText?: string;
  showTimeZone?: boolean;
  showEndOfDay?: boolean;
  dataCy?: string;
}

const DateTimeZone = ({
  className,
  date,
  fnsFormat,
  preText,
  showEndOfDay,
  dataCy,
  showTimeZone = false,
}: Props) => {
  const appContext = React.useContext(AppContext);

  if (!date) {
    return <div className={className}>--/--/--</div>;
  }

  let timeZoneString: string;
  let newFormat: string = fnsFormat;
  let newDate: Date = typeof date === 'string' ? new Date(date) : date;

  if (showTimeZone) {
    newFormat = `${fnsFormat} zzz`;
    timeZoneString = format(newDate, newFormat);
  } else {
    newDate = appContext.keepTimezoneConvertTime(newDate);

    timeZoneString = format(newDate, newFormat);

    if (showEndOfDay) {
      const time = format(newDate, 'h:mma');

      if (time === '11:00PM' || time === '11:30PM' || time === '11:59PM') {
        newFormat = newFormat.replace(' h:mma', '');
        timeZoneString = `${format(newDate, newFormat)} by End of Day`;
      }
    }
  }

  return (
    <div className={className} data-cy={dataCy}>
      {preText ? `${preText} ${timeZoneString}` : timeZoneString}
    </div>
  );
};

export default DateTimeZone;
