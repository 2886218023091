import cn from 'classnames';
import * as React from 'react';
import * as css from './index.scss';
import { IoIosCheckmarkCircleOutline } from '@react-icons/all-files/io/IoIosCheckmarkCircleOutline';
import { IoIosRadioButtonOff } from '@react-icons/all-files/io/IoIosRadioButtonOff';
import { Field as HField, Radio, Label as HLabel } from '@headlessui/react';

export interface RadioProps {
  // field: FieldInputProps<boolean>; // We don't need anything from Formik, since onChange is handled by RadioTabs (RadioGroup)
  value: string | boolean;
  dataCy?: string;
  labelComponent: React.ReactNode | string;
  className?: string;
  size?: 'sm' | 'md';
  disabled?: boolean;
  onChange?: (event) => void;
}

const RadioTab = ({
  size = 'md',
  labelComponent,
  className,
  dataCy,
  onChange,
  value,
  ...props
}: RadioProps) => (
  <HField
    className={cn(css.tab, className, {
      [css.disabled]: props.disabled,
    })}
  >
    <Radio
      data-cy={dataCy || `radioTab-${value}`}
      value={value}
      className={cn('relative z-0 m-0 flex flex-1 flex-grow flex-row flex-nowrap p-0', {
        'pointer-events-none cursor-not-allowed': props.disabled,
      })}
      onChange={onChange}
    >
      {({ checked, focus }) => (
        <div
          className={cn(css.button, 'leading-0 !p-2 !px-2 !text-left', css[`size--${size}`], {
            '!border-2 !border-solid !border-blue': checked || focus,
            [css.disabled]: props.disabled,
          })}
        >
          <div className="flex w-full items-center gap-2 p-0">
            <IoIosCheckmarkCircleOutline
              className={cn(
                'hidden h-6 w-6 flex-none transform text-blue transition duration-150 hover:text-blue-900',
                {
                  '!hidden': !checked,
                  '!block': checked,
                },
              )}
            />
            <IoIosRadioButtonOff
              className={cn(
                'h-6 w-6 flex-none transform text-default transition duration-150 hover:text-blue-900',
                {
                  '!block': !checked,
                  '!hidden': checked,
                },
              )}
            />
            <HLabel className="grow-1 mt-[2px] font-hvLite text-sm font-normal leading-4 cursor-pointer">
              {labelComponent}
            </HLabel>
          </div>
        </div>
      )}
    </Radio>
  </HField>
);

export default RadioTab;
