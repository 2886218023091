import * as React from 'react';
import cn from 'classnames';
import { LineItem } from 'api/orders/types';
import Span from 'styleguide/components/Span/Span';
import startCase from 'lodash-es/startCase';
import Collapse from 'styleguide/components/Collapse/Collapse';
import IfMobile from 'styleguide/components/Conditional/IfMobile';
import IfTabletOrDesktop from 'styleguide/components/Conditional/IfTabletOrDesktop';
import ProductDetails from 'styleguide/components/ProductsTable/ProductDetails/ProductDetails';
import ProductDetailsTabs from 'styleguide/components/ProductsTable/PoductDetailsTabs/ProductDetailsTabs';

const ProductTitle = ({ lineItem, withBackground, dataCy }) => (
  <Span data-cy={dataCy} className={cn('mb-2 flex !font-hvBold', withBackground && 'text-blue -md:mb-0')}>
    {lineItem.alias ? startCase(lineItem.alias) : lineItem.product.name}
  </Span>
);

interface Props {
  lineItem: LineItem;
  deliveryDate?: string;
  initiallyExpanded?: boolean;
  withBackground?: boolean;
}

const ProductDescription = ({ lineItem, deliveryDate, initiallyExpanded, withBackground }: Props) => {
  const [open, setOpen] = React.useState(false);
  const quoterJson = lineItem.quoterJson;
  const tabs = quoterJson.tabDividers && quoterJson.tabDividers !== 'no-tab-dividers';

  const handleCollapseItemToggle = () => setOpen(!open);

  if (!withBackground) {
    return (
      <>
        <ProductTitle
          dataCy={`lineItemTitle-${lineItem.id}`}
          lineItem={lineItem}
          withBackground={withBackground}
        />
        <ProductDetails lineItem={lineItem} deliveryDate={deliveryDate} />
        {tabs && <ProductDetailsTabs lineItem={lineItem} withBackground={withBackground} />}
      </>
    );
  }

  return (
    <>
      <IfMobile>
        <Collapse className="mb-6">
          <Collapse.Item
            title={
              <ProductTitle
                dataCy={`lineItemTitle-${lineItem.id}`}
                lineItem={lineItem}
                withBackground={withBackground}
              />
            }
            body={
              <>
                <ProductDetails lineItem={lineItem} deliveryDate={deliveryDate} />
                {tabs && <ProductDetailsTabs lineItem={lineItem} withBackground={withBackground} />}
              </>
            }
            classNameTitle={cn(
              'border border-neutral-200 rounded-xl p-4 bg-neutral-50',
              open || initiallyExpanded ? 'border-b-0 rounded-b-none pb-2' : '',
            )}
            iconClassName="!w-2 !h-2"
            onToggle={handleCollapseItemToggle}
            isWide
            classNameBody={cn(
              '!p-4 border border-neutral-200 rounded-xl bg-neutral-50',
              open || initiallyExpanded ? 'border-t-0 rounded-t-none !pt-0' : '',
            )}
            initiallyExpanded={initiallyExpanded}
          />
        </Collapse>
      </IfMobile>
      <IfTabletOrDesktop className="!mt-0 flex min-h-[240px] items-center rounded-lg bg-neutral-50 p-6 shadow-lg">
        <ProductTitle
          dataCy={`lineItemTitle-${lineItem.id}`}
          lineItem={lineItem}
          withBackground={withBackground}
        />
        <ProductDetails lineItem={lineItem} deliveryDate={deliveryDate} />
        {tabs && <ProductDetailsTabs lineItem={lineItem} withBackground={withBackground} />}
      </IfTabletOrDesktop>
    </>
  );
};

export default ProductDescription;
