import * as React from 'react';
import { Shipment } from 'api/orders/types';
import { formatMoney } from 'utils/money';

interface Props {
  shipment: Shipment;
}

const ShippingCost = ({ shipment }: Props) =>
  shipment.deliveryDate && +shipment.cost === 0.0 ? (
    <span className="caption-bold text-green-500">Free Shipping!</span>
  ) : (
    <span className="text-base" data-cy="shippingCost">
      {formatMoney(shipment.cost)}
    </span>
  );

export default ShippingCost;
