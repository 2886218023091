import * as React from 'react';
import Footer from 'styleguide/components/Footer/Footer';
import { Address } from './types';
import { Status } from 'libs/utils/api/types';
import { AddressesResponse } from 'api/types';
import { createAddress, getAddresses, updateAddress } from 'api/account/addresses';
import { useDebounce } from 'use-debounce';
import Search from 'bundles/App/pages/Account/Search/Search';
import Breadcrumb from 'styleguide/components/Breadcrumb/Breadcrumb';
import TileHeader from 'styleguide/components/TileHeader/TileHeader';
import ValidateAddressContextContainer from 'contexts/ValidateAddressContext/ValidateAddressContextContainer';
import DefaultAddressesForm from 'bundles/App/pages/Account/Addresses/DefaultAddressesForm';
import AddressesList from 'bundles/App/pages/Account/Addresses/AddressesList';
import UserContext from 'contexts/UserContextContainer/UserContext';

const AddressesPage = () => {
  const { updateAddresses } = React.useContext(UserContext);
  const [addresses, setAddresses] = React.useState<Address[]>([]);
  const [search, setSearch] = React.useState('');

  const [debouncedSearch] = useDebounce(search, 500);

  const refreshAddressList = () => {
    const handleSuccess = (response: AddressesResponse) => {
      setAddresses(response.addresses);
    };

    getAddresses(search).then(res => {
      if (res.status === Status.Ok) {
        handleSuccess(res.payload);
      }
    });
  };

  React.useEffect(() => {
    refreshAddressList();
  }, [debouncedSearch]);

  return (
    <ValidateAddressContextContainer
      onSaveValidAddress={(newAddress: Address) => {
        if (newAddress.id) {
          updateAddress(newAddress).then(res => {
            if (res.status === Status.Ok) {
              setAddresses(res.payload.addresses);
              updateAddresses(res.payload.addresses.filter(a => a.verified));
            }
          });
        } else {
          createAddress(newAddress).then(res => {
            if (res.status === Status.Ok) {
              setAddresses(res.payload.addresses);
              updateAddresses(res.payload.addresses.filter(a => a.verified));
            }
          });
        }
      }}
    >
      <TileHeader
        title="Saved addresses"
        dataCy="addressPageTitle"
        Breadcrumb={<Breadcrumb pages={[{ label: 'Home', link: '/' }, { label: 'Saved addresses' }]} />}
      />
      <DefaultAddressesForm />
      <div className="px-4 mx-auto mb-6 w-full md:w-96">
        <Search
          placeholder="Search by name, address, city, state, or zipcode"
          dataCy="addressSearchInput"
          handleSearch={newSearchField => setSearch(newSearchField)}
        />
      </div>
      <AddressesList addresses={addresses} setAddresses={setAddresses} />
      <Footer />
    </ValidateAddressContextContainer>
  );
};

export default AddressesPage;
