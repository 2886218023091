// extracted by mini-css-extract-plugin
var _1 = "Button__admin__YxGZw";
var _2 = "Button__button__uYC93";
var _3 = "Button__color--blue__tvx2M";
var _4 = "Button__color--dark__vZCAR";
var _5 = "Button__color--orange__CcqbT";
var _6 = "Button__color--text__sJiEA";
var _7 = "Button__disabled--blue__VY6AT";
var _8 = "Button__disabled--dark__Coww0";
var _9 = "Button__disabled--orange__QoU8m";
var _a = "Button__disabled--text__hNI_4";
var _b = "Button__expanded__lGZCz";
var _c = "Button__outline--blue__Cnj4h";
var _d = "Button__outline--dark__Bv0QO";
var _e = "Button__outline--disabled--blue__Ng7sB";
var _f = "Button__outline--disabled--dark__HmcAg";
var _10 = "Button__outline--disabled--orange__prSrZ";
var _11 = "Button__outline--orange__evU7I";
var _12 = "Button__size--lg__R0paC";
var _13 = "Button__size--md__BbkM1";
var _14 = "Button__size--sm__oW02p";
var _15 = "Button__size--xs__fP3bF";
var _16 = "Button__text--bold__wdljw";
var _17 = "Button__text--medium__G5tiK";
var _18 = "Button__text--normal__jM4JE";
export { _1 as "admin", _2 as "button", _3 as "color--blue", _4 as "color--dark", _5 as "color--orange", _6 as "color--text", _7 as "disabled--blue", _8 as "disabled--dark", _9 as "disabled--orange", _a as "disabled--text", _b as "expanded", _c as "outline--blue", _d as "outline--dark", _e as "outline--disabled--blue", _f as "outline--disabled--dark", _10 as "outline--disabled--orange", _11 as "outline--orange", _12 as "size--lg", _13 as "size--md", _14 as "size--sm", _15 as "size--xs", _16 as "text--bold", _17 as "text--medium", _18 as "text--normal" }
