import React from 'react';
import { useCarousel } from 'nuka-carousel';
import CarouselBottomArrows from './CarouselBottomArrows';
import CarouselCardArrows from './CarouselCardArrows';

export const DEFAULT_AUTOPLAY_SLIDE_MS = 3000;
export const DEFAULT_AUTOPLAY_SUSPENSION_MS = 8000;

type CarouselArrowsProps = {
  autoplay?: boolean;
  autoplaySlideMs?: number;
  autoplaySuspensionMs?: number;
  mode: 'card' | 'bottom';
};

const CarouselArrows = ({
  autoplay = false,
  autoplaySlideMs = DEFAULT_AUTOPLAY_SLIDE_MS,
  autoplaySuspensionMs = DEFAULT_AUTOPLAY_SUSPENSION_MS,
  mode,
}: CarouselArrowsProps) => {
  const [autoplayDisabledAt, setAutoplayDisabledAt] = React.useState(0);
  const [expectingPage, setExpectingPage] = React.useState(0);

  const { currentPage, totalPages, goBack, goForward } = useCarousel();

  React.useEffect(() => {
    if (!autoplay || !totalPages || expectingPage === currentPage) return;

    setAutoplayDisabledAt(Date.now());
    setExpectingPage(currentPage);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPages, currentPage]);

  React.useEffect(() => {
    if (!autoplay || !totalPages) return () => {};

    if (autoplayDisabledAt === 0) {
      const autoplayInterval = setInterval(() => {
        setExpectingPage(curr => (curr + 1) % totalPages);
      }, autoplaySlideMs);

      return () => {
        clearInterval(autoplayInterval);
      };
    }

    const reenableAutoplayTimeout = setTimeout(() => {
      setAutoplayDisabledAt(0);
    }, autoplaySuspensionMs);

    return () => {
      clearTimeout(reenableAutoplayTimeout);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoplay, autoplaySlideMs, autoplaySuspensionMs, totalPages, autoplayDisabledAt]);

  React.useEffect(() => {
    if ((currentPage + 1) % totalPages === expectingPage) goForward();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expectingPage]);

  switch (mode) {
    case 'card':
      return <CarouselCardArrows goBack={goBack} goForward={goForward} />;
    case 'bottom':
      return <CarouselBottomArrows goBack={goBack} goForward={goForward} />;
    default: {
      const exhaustiveCheck: never = mode;
      throw new Error(`Unhandled mode: ${exhaustiveCheck}`);
    }
  }
};

export default CarouselArrows;
