import * as React from 'react';
import cn from 'classnames';
import * as css from './Heading.scss';

interface Props extends React.HTMLProps<HTMLHeadingElement> {
  light?: boolean;
}

const H4 = ({ children, className, light, ...otherProps }: Props) => (
  <h4 className={cn(css.Heading, css.H4, { [css[`H4--light`]]: !!light }, className)} {...otherProps}>
    {children}
  </h4>
);

export default H4;
