import { Color, Underline } from '../types';

import * as css from '../index.scss';

interface Args {
  color: Color;
  underline: Underline;
}
interface ComputedClassName {
  [key: string]: boolean | null;
}

export const computeLinkClassName = ({ color = 'blue', underline = 'active' }: Args): ComputedClassName => ({
  [css.link]: true,
  [css[`underline--${underline}`]]: true,
  [css[`color--${color}`]]: true,
});
