import * as React from 'react';
import cn from 'classnames';
import * as css from './ProofApprovalsPage.scss';
import Grid from 'styleguide/components/Grid/Grid';
import Span from 'styleguide/components/Span/Span';
import CustomerServiceContact from './CustomerServiceContact';
import NavigationButtons from 'bundles/App/pages/ProofApprovalsPage/NavigationButtons';
import { Reservation } from 'api/admin/orders/types';

export interface Props {
  reservation: Reservation;
}

const Expired = ({ reservation }: Props) => (
  <>
    <Grid.Row>
      <Grid.Col>
        <Span data-cy="proofApprovalExpiredPageTitle" className={cn(css.title, css.expired)}>
          Proof Expired
        </Span>
      </Grid.Col>
    </Grid.Row>
    <Grid.Row className={css.bodyContainer}>
      <Grid.Col>
        <Span>
          Oh no! Your approval deadline for your proof has expired. Please contact your customer service
          representative to discuss the necessary steps to proceed with your order. Their contact information
          is below.{' '}
        </Span>
      </Grid.Col>
    </Grid.Row>
    <Grid.Row>
      <Grid.Col>
        <CustomerServiceContact reservation={reservation} />
      </Grid.Col>
    </Grid.Row>
    <NavigationButtons />
  </>
);

export default Expired;
