import apiCall from 'libs/utils/api/apiCall';
import queryString from 'qs';
import { CouponCodeResponse, OrderTotalsHash, ProductionCheckpoint, Shipment } from './orders/types';
import { Facility } from './admin/orders/types';
import { ApiResponse, SharedErrors, Status } from 'libs/utils/api/types';
import { Address } from 'bundles/App/pages/Account/Addresses/types';
import snakecaseKeys from 'snakecase-keys';
import { format, isValid } from 'date-fns';

interface RushDeliveryResponse {
  minDate: string;
  seconds: number;
}

export const doRushDeliveryRequest = (productName, rushQuote) =>
  apiCall.get<RushDeliveryResponse, SharedErrors>({
    url: `/simply_ship/countdown_date_for_products?${queryString.stringify({
      minimum_production_time: JSON.stringify(rushQuote),
      id: productName,
    })}`,
    unscoped: true,
  });

export const doCustomizeRequest = (orderNumber, shipmentId, isCustomized) =>
  apiCall.get<{ status: Status.Ok }, SharedErrors>({
    url: `/admin/simply_ship/${orderNumber}/shipment/${shipmentId}/${isCustomized ? 'un' : ''}customize`,
    unscoped: true,
  });

export interface UpdateDatePickerResponse {
  dates: {
    minDate: string;
    maxDate: string;
    freeShippingDates?: string[];
    rushCriticalDates: string[] | false;
    normalDates: string[];
  };
  shipment: Shipment;
}

export const doUpdateDatePickerRequest = (shipmentId, address, admin, orderNumber, readyAtDate: Date) => {
  let url = '';

  if (admin) {
    url = `/admin/simply_ship/update_datepicker`;
  } else {
    url = `/simply_ship/update_datepicker`;
  }

  return apiCall.get<UpdateDatePickerResponse, SharedErrors>({
    url: `${url}?${queryString.stringify(
      snakecaseKeys({
        orderNumber,
        shipmentId,
        address,
        startDate: readyAtDate && isValid(readyAtDate) ? format(readyAtDate, 'M/d/yyyy') : null,
      }),
    )}`,
    unscoped: true,
  });
};

export interface UpdateOrderForNewArrivalResponse extends OrderTotalsHash {
  timeOfDays?: string[];
  productionDays: number;
  shipment: {
    shippingDays: number;
    shippingMethod: string;
    deliveryDate: string;
  };
}

export const defaultDeliveryTime = '11:59PM'; // End of day
export const pickUpTime = '4:00PM';

export interface CustomShipment {
  shipDate: string;
  deliveryDate: string;
  shippingMethodId: number;
  userShippingAccountId: number;
  cost: number;
  internalCost: number;
}

export interface NewArrival {
  address: Address;
  readyAtDate: Date;
  deliveryDate: Date;
  selectedTime?: string;
  freeShipping?: boolean;
}

export interface SimplyShipResponse {
  readyAt: string;
  shipment: Shipment;
  shippingAddress?: Address;
  billingAddress?: Address;
  facility?: Facility;
  productionDays: number;
  productionCheckpoints: ProductionCheckpoint[];
  updateOrderForNewArrivalResponse: UpdateOrderForNewArrivalResponse;
}

export type UpdateOrderForNewArrivalRequestSignature = (
  admin,
  save,
  orderNumber,
  shipmentId,
  newArrival: NewArrival,
) => ApiResponse<SimplyShipResponse, SharedErrors>;

export const doUpdateOrderForNewArrivalRequest: UpdateOrderForNewArrivalRequestSignature = (
  admin,
  save,
  orderNumber,
  shipmentId,
  newArrival: NewArrival,
) => {
  let selectedTimeM = newArrival.selectedTime;
  if (!!selectedTimeM && selectedTimeM.length === 6) {
    selectedTimeM = `0${selectedTimeM}`;
  }

  const query = queryString.stringify(
    snakecaseKeys(
      {
        orderNumber,
        shipmentId,
        address: newArrival.address,
        startDate:
          newArrival.readyAtDate && isValid(newArrival.readyAtDate)
            ? format(newArrival.readyAtDate, 'M/d/yyyy')
            : null,
        deliveryDate: format(newArrival.deliveryDate, 'M/d/yyyy'),
        timeOfDay: selectedTimeM,
        freeShipping: newArrival.freeShipping,
        save,
      },
      { deep: true },
    ),
    { arrayFormat: 'brackets' },
  );

  let url: string;

  if (admin) {
    if (save) {
      url = `/admin/simply_ship/update_order_for_new_arrival?${query}`;
    } else {
      url = `/admin/simply_ship/totals_for_new_arrival?${query}`;
    }
  } else {
    url = `/simply_ship/update_order_for_new_arrival?${query}`;
  }
  return apiCall.get<SimplyShipResponse, SharedErrors>({
    url,
    unscoped: true,
  });
};

export const doApplyPromoCodeRequest = (promoCode: string, shouldRemovePromotion = false) =>
  apiCall.patch<CouponCodeResponse, SharedErrors>({
    url: '/cart',
    unscoped: true,
    data: { order: { couponCode: promoCode, removeCouponCode: shouldRemovePromotion } },
  });
