import * as React from 'react';
import * as css from './ProductDetailsNavbar.scss';
import cn from 'classnames';
import { NavContainer } from 'styleguide/components';
import Link from 'styleguide/components/Links/Link';
import Image from 'styleguide/components/Image/Image';
import logo from 'assets/images/printivity-logo-mobile.png';
import CartIcon from 'styleguide/components/Nav/CartIcon/CartIcon';
import IfDesktop from 'styleguide/components/Conditional/IfDesktop';
import IfTabletOrMobile from 'styleguide/components/Conditional/IfTabletOrMobile';
import { Transition } from '@headlessui/react';

interface Props {
  disableFeatures?: boolean;
  disableTemplates?: boolean;
  disableFAQ?: boolean;
  disableBranding?: boolean;
  disableReviews?: boolean;
  disableMoreResources?: boolean;
  disableSEOBlock?: boolean;
  disableArticles?: boolean;
}

const ProductDetailsNavbar = ({
  disableFeatures,
  disableTemplates,
  disableFAQ,
  disableBranding,
  disableReviews,
  disableMoreResources,
  disableSEOBlock,
  disableArticles,
}: Props) => {
  const [scrollTop, setScrollTop] = React.useState(0);
  const [innerNavOffset, setInnerNavOffset] = React.useState(0);
  const [innerNavigationIsSticky, setInnerNavigationIsSticky] = React.useState(false);
  const [currentSection, setCurrentSection] = React.useState('features');
  const sections = [
    'features',
    'why-printivity',
    'reviews',
    'templates-and-specs',
    'articles',
    'faq',
    'more-resources',
    'more-information',
  ];
  const elIsOnTop = (el: HTMLElement) => {
    if (el.offsetHeight + el.getBoundingClientRect().top > 78) {
      return true;
    }
    return false;
  };

  const findElOnTop = () => {
    const elOnTop = sections.find(section => {
      const el = document.getElementById(`${section}-body`);
      if (el && elIsOnTop(el)) {
        return true;
      }
      return false;
    });
    return elOnTop;
  };

  const scrollTo = (id: string) => {
    const el = document.getElementById(id);
    if (el) {
      el.scrollIntoView({ behavior: 'smooth' });
    }
  };

  React.useEffect(() => {
    const innerNav = document.getElementById('innerNav');

    const onScroll = e => {
      if (innerNavOffset && innerNavOffset < window.pageYOffset) {
        setInnerNavigationIsSticky(true);
      } else {
        setInnerNavigationIsSticky(false);
      }
      setScrollTop(e.target.documentElement.scrollTop);
      setInnerNavOffset(window.pageYOffset + innerNav.getBoundingClientRect().y);
      setCurrentSection(findElOnTop());
    };

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [scrollTop]);

  return (
    <div className="h-[78px] w-full">
      <Transition
        as="div"
        show={innerNavigationIsSticky}
        enter="transition ease-out duration-150"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          className={cn(
            `left fixed z-100 flex h-[54px] max-w-[100vw] items-center 
border-b border-gray-200 bg-shades-0 shadow-sm shadow-gray-200 md:max-w-screen-4xl -md:h-[46px]`,
          )}
        >
          <div
            className={cn('relative flex w-[100vw] md:justify-around -sm:overflow-x-scroll', css.navScroll)}
          >
            <Link to="/" color="none" underline="none" className="flex items-center">
              <Image url={logo} title="Printivity" alt="Printivity" className="h-[24px] w-[60px]" />
            </Link>
            {!disableFeatures && (
              <div
                onClick={() => scrollTo('features')}
                className={cn(
                  `paragraph-underline-mobile flex h-[54px]
           cursor-pointer items-center font-hvMedium text-default no-underline hover:underline 
           -md:mx-3 -md:h-[46px] -md:min-w-fit`,
                  currentSection === 'features' ? 'border-0 border-b-2 border-solid border-b-blue-700' : '',
                )}
                onKeyDown={() => scrollTo('features')}
                role="tab"
                tabIndex={0}
              >
                Product Features
              </div>
            )}
            {!disableBranding && (
              <div
                onClick={() => scrollTo('why-printivity')}
                className={cn(
                  `paragraph-underline-mobile flex h-[54px]
           cursor-pointer items-center font-hvMedium text-default no-underline hover:underline 
           -md:mx-3 -md:h-[46px] -md:min-w-fit`,
                  currentSection === 'why-printivity'
                    ? 'border-0 border-b-2 border-solid border-b-blue-700'
                    : '',
                )}
                onKeyDown={() => scrollTo('why-printivity')}
                role="tab"
                tabIndex={0}
              >
                Why Printivity
              </div>
            )}
            {!disableReviews && (
              <div
                onClick={() => scrollTo('reviews')}
                onKeyDown={() => scrollTo('reviews')}
                className={cn(
                  `paragraph-underline-mobile flex h-[54px]
           cursor-pointer items-center font-hvMedium text-default no-underline hover:underline 
           -md:mx-3 -md:h-[46px] -md:min-w-fit`,
                  currentSection === 'reviews' ? 'border-0 border-b-2 border-solid border-b-blue-700' : '',
                )}
                role="tab"
                tabIndex={0}
              >
                Reviews
              </div>
            )}
            {!disableTemplates && (
              <div
                onClick={() => scrollTo('templates-and-specs')}
                onKeyDown={() => scrollTo('templates-and-specs')}
                className={cn(
                  `paragraph-underline-mobile flex h-[54px]
           cursor-pointer items-center font-hvMedium text-default no-underline hover:underline 
           -md:mx-3 -md:h-[46px] -md:min-w-fit`,
                  currentSection === 'templates-and-specs'
                    ? 'border-0 border-b-2 border-solid border-b-blue-700'
                    : '',
                )}
                role="tab"
                tabIndex={0}
              >
                Templates & Specs
              </div>
            )}
            {!disableArticles && (
              <div
                onClick={() => scrollTo('articles')}
                onKeyDown={() => scrollTo('articles')}
                className={cn(
                  `paragraph-underline-mobile flex h-[54px]
           cursor-pointer items-center font-hvMedium text-default no-underline hover:underline 
           -md:mx-3 -md:h-[46px] -md:min-w-fit`,
                  currentSection === 'articles' ? 'border-0 border-b-2 border-solid border-b-blue-700' : '',
                )}
                role="tab"
                tabIndex={0}
              >
                Articles
              </div>
            )}
            {!disableFAQ && (
              <div
                onClick={() => scrollTo('faq')}
                onKeyDown={() => scrollTo('faq')}
                className={cn(
                  `paragraph-underline-mobile flex h-[54px]
           cursor-pointer items-center font-hvMedium text-default no-underline hover:underline 
           -md:mx-3 -md:h-[46px] -md:min-w-fit`,
                  currentSection === 'faq' ? 'border-0 border-b-2 border-solid border-b-blue-700' : '',
                )}
                role="tab"
                tabIndex={0}
              >
                FAQ
              </div>
            )}
            {!disableMoreResources && (
              <div
                onClick={() => scrollTo('more-resources')}
                onKeyDown={() => scrollTo('more-resources')}
                className={cn(
                  `paragraph-underline-mobile flex h-[54px]
           cursor-pointer items-center font-hvMedium text-default no-underline hover:underline 
           -md:mx-3 -md:h-[46px] -md:min-w-fit`,
                  currentSection === 'more-resources'
                    ? 'border-0 border-b-2 border-solid border-b-blue-700'
                    : '',
                )}
                role="tab"
                tabIndex={0}
              >
                More Resources
              </div>
            )}
            {!disableSEOBlock && (
              <div
                onClick={() => scrollTo('more-information')}
                onKeyDown={() => scrollTo('more-information')}
                className={cn(
                  `paragraph-underline-mobile flex h-[54px]
           cursor-pointer items-center font-hvMedium text-default no-underline hover:underline 
           -md:mx-3 -md:h-[46px] -md:min-w-fit`,
                  currentSection === 'more-information'
                    ? 'border-0 border-b-2 border-solid border-b-blue-700'
                    : 'transition-width after:m-auto after:block after:h-[2px] after:w-0 after:bg-blue-700 after:content-[""] hover:text-gray-300 hover:after:w-full',
                )}
                role="tab"
                tabIndex={0}
              >
                More Information
              </div>
            )}
            <CartIcon />
          </div>
        </div>
      </Transition>
      {!innerNavigationIsSticky && (
        <>
          <IfTabletOrMobile>
            <NavContainer />
          </IfTabletOrMobile>
          <IfDesktop>
            <NavContainer notSticky />
          </IfDesktop>
        </>
      )}
    </div>
  );
};
export default ProductDetailsNavbar;
