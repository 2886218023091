// extracted by mini-css-extract-plugin
var _1 = "ProductSelector__productDescription__NL5nB";
var _2 = "ProductSelector__productImage__r5u06";
var _3 = "ProductSelector__productName__EESuS";
var _4 = "ProductSelector__productSelectorCard__rFCMc";
var _5 = "ProductSelector__productSelectorLabel__DctFs";
var _6 = "ProductSelector__round__Zjgvb";
var _7 = "ProductSelector__selectedProductSelectorCard__QYJxF";
var _8 = "ProductSelector__selectedProductSelectorLabel__m1IOx";
var _9 = "ProductSelector__selectedRound__N20ke";
export { _1 as "productDescription", _2 as "productImage", _3 as "productName", _4 as "productSelectorCard", _5 as "productSelectorLabel", _6 as "round", _7 as "selectedProductSelectorCard", _8 as "selectedProductSelectorLabel", _9 as "selectedRound" }
