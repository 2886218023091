import * as React from 'react';
import cn from 'classnames';
import Nav from 'styleguide/components/Nav/Tailwind/Nav';
import MobileNav from 'styleguide/components/Nav/MobileNav/MobileNav';
import { Link as RouterLink } from 'react-router-dom';
import HoverProvider from 'styleguide/components/Nav/Tailwind/HoverProvider/HoverProvider';
import ScreenSizeContext, { ScreenSize } from 'contexts/ScreenSizeContext/ScreenSizeContext';

type CustomerServiceMessageProps = { messageType: 'short' | 'long' };
const CustomerServiceMessage = ({ messageType }: CustomerServiceMessageProps) => {
  const start =
    messageType === 'short' ? 'Questions? Contact our' : 'If you have any questions, please contact our';
  return (
    <div className="block w-full">
      {start}&nbsp;
      <RouterLink
        to="/contact-us"
        className={`cursor-pointer font-hvBold text-default underline transition
                    duration-300 ease-in-out hover:text-gray-700`}
      >
        customer service department
      </RouterLink>
      .
    </div>
  );
};

interface Props {
  notSticky?: boolean;
}

const NavContainer = ({ notSticky }: Props) => {
  // const location = useLocation();
  // const matchPaths = [/cart/, /account(\/.*)*/];
  // const delayMessage: boolean = matchPaths.some(path => path.test(location.pathname));
  const delayMessage: boolean = false;
  const { screenSize } = React.useContext(ScreenSizeContext);
  const [navKey, setNavKey] = React.useState(0);

  const [displayedNav, customerServiceMessage] = React.useMemo(() => {
    // Do not display the components on the first render, since `screenSize` was
    // not evaluated yet.
    if (navKey === 0) return [null, null];

    switch (screenSize) {
      case ScreenSize.LG:
        return [
          <div className="block w-full px-4">
            <HoverProvider>
              <Nav />
            </HoverProvider>
          </div>,
          <CustomerServiceMessage messageType="long" />,
        ];
      case ScreenSize.MD:
      case ScreenSize.SM:
        return [<MobileNav />, <CustomerServiceMessage messageType="short" />];
      default: {
        const exhaustiveCheck: never = screenSize;
        throw new Error(`Unhandled ScreenSize: ${exhaustiveCheck}`);
      }
    }
  }, [navKey, screenSize]);

  React.useEffect(() => {
    // Ensure that a second render will take place.
    setTimeout(() => setNavKey(Date.now()), 1);
  }, []);

  return (
    <>
      <div
        key={navKey}
        className={cn(
          'fixed left-1/2 z-100 w-full max-w-screen-4xl -translate-x-1/2 bg-shades-0 ' +
            'border-b border-gray-200 shadow-sm shadow-gray-200',
          notSticky && 'relative',
          delayMessage ? 'h-32' : 'h-20',
        )}
      >
        {delayMessage && (
          <div
            className="flex h-12 w-full flex-col items-center justify-center bg-green-200 px-4 text-center
        text-xs sm:px-1 md:text-sm lg:text-base"
          >
            <div className={cn('font-hvBold')}>
              In observance of the holidays, Printivity&apos;s offices will be closed on Monday, December 25th
              and Monday, January 1st.
            </div>
            {customerServiceMessage}
          </div>
        )}
        {displayedNav}
      </div>
      <div className={cn(delayMessage ? 'h-28 lg:h-32' : 'h-16 lg:h-20', notSticky && 'hidden')} />
    </>
  );
};
export default NavContainer;
