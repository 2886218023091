import * as React from 'react';
import queryString from 'qs';
import camelcaseKeys from 'camelcase-keys';
import Routes from './routes';
import UserContextContainer from 'contexts/UserContextContainer/UserContextContainer';
import { User } from 'contexts/UserContextContainer/types';
import { Alert } from 'api/types';
import PersephoneContextContainer from 'contexts/PersephoneContextContainer/PersephoneContextContainer';
import OrderContextContainer from 'contexts/OrderContext/OrderContextContainer';
import { ReactOnRailsContext, Store, Options } from 'contexts/AppContext/types';
import AppContextContainer from 'contexts/AppContext/AppContextContainer';
import { RedirectModal } from './DynamicPages.imports-loadable';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Banner from 'styleguide/components/Banner/Banner';
import { useLocation } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import AddressValidationModal from 'styleguide/components/AddressValidationModal/AddressValidationModal';
import ScreenSizeContextContainer from 'contexts/ScreenSizeContext/ScreenSizeContextContainer';
import useDelayedScriptLoader from 'utils/hooks/useDelayedScriptLoader';
import ZendeskChatWidget from 'styleguide/components/Zendesk/ZendeskChatWidget';
import { ScreenSize } from 'app/contexts/ScreenSizeContext/ScreenSizeContext';

export interface AppProps {
  alerts: Alert[];
  store: Store;
  user?: User;
  options: Options;
  location: {
    pathname: string;
  };
  message?: string;
}

interface Props extends AppProps {
  railsContext: ReactOnRailsContext;
}

const App = (appProps: Props) => {
  const props: Props = camelcaseKeys({ ...appProps }, { deep: true });
  const location = useLocation();
  const [showPopup, setShowPopup] = React.useState(
    typeof window !== 'undefined' &&
      queryString.parse(location.search, { ignoreQueryPrefix: true }).r === 'mgxcopy',
  );
  const { railsContext } = appProps;
  const { persephoneToken, persephoneEndpoint, railsEnv } = railsContext;
  useDelayedScriptLoader(() => {
    if (railsEnv === 'production' && !!process.env.ENABLE_ANALYTICS_TRACKING) {
      TagManager.initialize({ gtmId: process.env.GTM_ID });
    }
  });

  return (
    <>
      <ToastContainer
        className="!w-[80%] md:!w-auto -md:!left-1/2 -md:!top-4 -md:!-translate-x-1/2"
        bodyClassName="w-fit"
        enableMultiContainer
        position="bottom-right"
        containerId="events"
        autoClose={5000}
        icon={false}
      />
      <ToastContainer
        className="!w-[80%] md:!w-auto -md:!left-1/2 -md:!top-4 -md:!-translate-x-1/2"
        bodyClassName="w-fit"
        enableMultiContainer
        position="top-center"
        containerId="default"
        autoClose={false}
        icon={false}
      />
      <ToastContainer
        className="!w-[80%] md:!w-auto -md:!left-1/2 -md:!top-4 -md:!-translate-x-1/2"
        bodyClassName="w-fit"
        enableMultiContainer
        position="top-center"
        containerId="autoclose"
        autoClose={3000}
        closeButton={false}
        icon={false}
      />
      <ZendeskChatWidget zendeskWidgetKey={railsContext.zendeskWidgetKey} />
      <AppContextContainer store={props.store} env={railsContext} options={props.options}>
        <PersephoneContextContainer
          token={persephoneToken}
          endpoint={persephoneEndpoint}
          options={[]}
          catalog={props.options.catalog}
        >
          <OrderContextContainer order={props.options.order}>
            <UserContextContainer currentUser={props.user} admin={false}>
              <ScreenSizeContextContainer initialScreenSize={railsContext.initialScreenSize as ScreenSize}>
                {!!showPopup && <RedirectModal onClose={() => setShowPopup(false)} />}
                {!!props.message && <Banner message={props.message} type="error" />}
                <AddressValidationModal />
                <Routes url={location.pathname} />
              </ScreenSizeContextContainer>
            </UserContextContainer>
          </OrderContextContainer>
        </PersephoneContextContainer>
      </AppContextContainer>
    </>
  );
};

export default App;
