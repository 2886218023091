// extracted by mini-css-extract-plugin
var _1 = "OrderConfirmation__HeroText__jVG6B";
var _2 = "OrderConfirmation__addressBox__RfZp5";
var _3 = "OrderConfirmation__cardAttention__VV6QD";
var _4 = "OrderConfirmation__cardContent__BM6ry";
var _5 = "OrderConfirmation__cardHeader__CCY1Q";
var _6 = "OrderConfirmation__cardMain__BzS58";
var _7 = "OrderConfirmation__cardText__auR10";
var _8 = "OrderConfirmation__cardTextAlignLeft__IYZyD";
var _9 = "OrderConfirmation__extraPad__Ivpgw";
var _a = "OrderConfirmation__headerBox__dg4md";
var _b = "OrderConfirmation__headerRight__Z4B2u";
var _c = "OrderConfirmation__headerText__ashqj";
var _d = "OrderConfirmation__headerTextLeft__ZU6Xr";
var _e = "OrderConfirmation__headerTextRight__pg_Gc";
var _f = "OrderConfirmation__heading__AUS5p";
var _10 = "OrderConfirmation__highlight__eVYkP";
var _11 = "OrderConfirmation__infoCardBody__MJyVL";
var _12 = "OrderConfirmation__infoCardHeader__pCOY2";
var _13 = "OrderConfirmation__infoContainer__UTpjH";
var _14 = "OrderConfirmation__infoRow__isX0E";
var _15 = "OrderConfirmation__leftAlign__ogj58";
var _16 = "OrderConfirmation__leftAlignContainer__TqSk2";
var _17 = "OrderConfirmation__orderDetailsBorder__x8KJf";
var _18 = "OrderConfirmation__orderDetailsContainer__M5jMV";
var _19 = "OrderConfirmation__orderDetailsSpacing__iLfK_";
var _1a = "OrderConfirmation__orderTitle__zUJFM";
var _1b = "OrderConfirmation__orderTotals__MWFS1";
var _1c = "OrderConfirmation__spacer__ZgbWB";
var _1d = "OrderConfirmation__stepItem__XBxms";
var _1e = "OrderConfirmation__stepNormal__zf1l8";
var _1f = "OrderConfirmation__stepWide__ks1_u";
var _20 = "OrderConfirmation__stepsContainer__eV5d6";
var _21 = "OrderConfirmation__stepsRow__wSzxJ";
var _22 = "OrderConfirmation__success__hmvfQ";
var _23 = "OrderConfirmation__successHeader__VZOWE";
var _24 = "OrderConfirmation__textBold__A47ek";
var _25 = "OrderConfirmation__total__m66p3";
export { _1 as "HeroText", _2 as "addressBox", _3 as "cardAttention", _4 as "cardContent", _5 as "cardHeader", _6 as "cardMain", _7 as "cardText", _8 as "cardTextAlignLeft", _9 as "extraPad", _a as "headerBox", _b as "headerRight", _c as "headerText", _d as "headerTextLeft", _e as "headerTextRight", _f as "heading", _10 as "highlight", _11 as "infoCardBody", _12 as "infoCardHeader", _13 as "infoContainer", _14 as "infoRow", _15 as "leftAlign", _16 as "leftAlignContainer", _17 as "orderDetailsBorder", _18 as "orderDetailsContainer", _19 as "orderDetailsSpacing", _1a as "orderTitle", _1b as "orderTotals", _1c as "spacer", _1d as "stepItem", _1e as "stepNormal", _1f as "stepWide", _20 as "stepsContainer", _21 as "stepsRow", _22 as "success", _23 as "successHeader", _24 as "textBold", _25 as "total" }
