import * as React from 'react';
import Modal from 'styleguide/components/Modal/Modal';
import * as css from './RedirectModal.scss';
import Image from 'styleguide/components/Image/Image';

import logo from 'assets/images/printivity-logo.png';
import signature from 'assets/images/signature.jpg';

interface Props {
  onClose: () => void;
}

const RedirectModal = ({ ...props }: Props) => (
  <Modal shown className={css.redirectModal} onClose={props.onClose}>
    <Image className={css.logo} alt="Printivity" url={logo} />
    <div className={css.contentText}>
      <p>MGX Copy is now Printivity. Thanks to you, we&apos;ve outgrown our name.</p>
    </div>
    <div className={css.contentText}>
      <p>
        Since we now print more stuff than ever, we&apos;ve decided that we need a name that better reflects
        what we can do for you. Rest assured, we&apos;re the same ownership and support team, so you can still
        rely on us for amazing quality and service.
      </p>
    </div>
    <div className={css.contentText}>
      <p>Our new and improved website is officially Printivity.com, so feel free to update your bookmarks.</p>
    </div>
    <div className={css.contentText}>
      <p>The next time you start a print project, we hope you think of us.</p>
    </div>
    <Image className={css.signature} alt="Signature" url={signature} />
    <div className={css.contentText}>Lawrence Chou</div>
    <div className={css.contentText}>CEO + Founder</div>
  </Modal>
);

export default RedirectModal;
