/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as React from 'react';

// import 'react-day-picker/style.css';
import './DatePicker.css';
import { format } from 'date-fns';
import { DayPicker, Formatters, Matcher } from 'react-day-picker';
import { usePopper } from 'react-popper';

import DateTime from './DateTime/DateTime';
import InputWrapper from './InputWrapper';
import Header from './Header';
import Footer from './Footer/Footer';
import { DEFAULT_DATE_FORMAT, DayType } from './constants';
import Span from '../Span/Span';
import A from '../Links/A';

interface Props {
  selectedDate: Date;
  startDate?: Date;
  endDate?: Date;
  twoMonths?: boolean;
  onDateSelect?: (date: Date) => void;
  onSelectTimeClick?: () => void;
  customText?: string;
  inputClass?: string;
  fixedPosition?: boolean;
  headerClassName?: string;
  disabledDays?: Matcher[];
  showUPSPolicy?: boolean;
  dates?: {
    freeShipping: Date[];
    critical: Date[];
    standard: Date[];
  };
  dataCy?: string;
  onClick?: () => void;
}

const formatCaption: Formatters['formatCaption'] = (month, options) =>
  format(month, 'LLL yyyy', { locale: options?.locale });

const formatWeekdayName: Formatters['formatWeekdayName'] = (day, options): string =>
  format(day, 'iii', { locale: options?.locale });

const GuaranteedDatesInfo = () => (
  <div className="flex w-full flex-col rounded-t-lg bg-yellow-50 px-1 py-2 text-center">
    <Span className="text-sm">
      Delivery dates are no longer guaranteed due to{' '}
      <A
        href="https://www.ups.com/us/en/help-center/shipping-support/service-guarantee.page"
        color="black"
        underline="always"
        targetBlank
      >
        UPS&apos;s change in policy.
      </A>
    </Span>
  </div>
);

const DatePicker = (props: Props) => {
  const {
    customText,
    selectedDate,
    onSelectTimeClick,
    headerClassName,
    disabledDays,
    twoMonths,
    dates,
    showUPSPolicy,
    onDateSelect,
    dataCy,
    onClick,
  } = props;

  const { freeShipping, critical, standard } = dates ?? {};

  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const [inputValue, setInputValue] = React.useState<string>('');
  const popperRef = React.useRef<HTMLDivElement>(null);
  const [popperElement, setPopperElement] = React.useState<HTMLDivElement | null>(null);
  const popper = usePopper(popperRef.current, popperElement, {
    placement: 'bottom-start',
  });
  const [datepickerShown, setDatepickerShown] = React.useState<boolean>(false);

  const handleInputChange = e => {
    setInputValue(e.currentTarget.value);
  };

  const handleDaySelect = (date: Date) => {
    if (date) {
      setInputValue(format(date, DEFAULT_DATE_FORMAT));
      if (!onSelectTimeClick) {
        setDatepickerShown(false);
      }
    } else {
      setInputValue('');
    }

    if (onDateSelect) {
      onDateSelect(date);
    }
  };

  const handleSelectTimeClick = () => {
    if (onSelectTimeClick) {
      onSelectTimeClick();
      setDatepickerShown(false);
    }
  };

  const toggleDayPicker = () => {
    setDatepickerShown(!datepickerShown);
  };

  const handleClickOutside = event => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setDatepickerShown(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return function unmount() {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const getDayType = (date: Date): DayType | null => {
    const formattedDate = format(date, 'yyyy-MM-dd');

    if (freeShipping?.map(d => format(d, 'yyyy-MM-dd')).includes(formattedDate)) {
      return DayType.FREE_SHIPPING;
    }
    if (critical?.map(d => format(d, 'yyyy-MM-dd')).includes(formattedDate)) {
      return DayType.CRITICAL;
    }
    if (standard?.map(d => format(d, 'yyyy-MM-dd')).includes(formattedDate)) {
      return DayType.STANDARD;
    }

    return null;
  };

  const selectedDayType = getDayType(selectedDate);

  return (
    <div data-cy={dataCy} ref={wrapperRef}>
      <div ref={popperRef} onClick={onClick} tabIndex={0} role="button" onKeyDown={() => {}}>
        <InputWrapper
          value={customText || inputValue}
          onWrapperClick={toggleDayPicker}
          onChange={handleInputChange}
          placeholder={format(new Date(), DEFAULT_DATE_FORMAT)}
        />
      </div>
      {datepickerShown && (
        <div
          tabIndex={-1}
          style={popper.styles.popper}
          className="dialog-sheet z-100 w-min shadow-lg"
          {...popper.attributes.popper}
          ref={setPopperElement}
          role="dialog"
        >
          {showUPSPolicy && <Header header={<GuaranteedDatesInfo />} headerClassName={headerClassName} />}
          <DayPicker
            mode="single"
            components={{
              // eslint-disable-next-line react/no-unstable-nested-components
              DayButton: dbprops => {
                const { day, modifiers, ...buttonProps } = dbprops;
                return (
                  <DateTime
                    dayType={getDayType(day.date)}
                    isSelected={
                      selectedDate.getDate() === day.date.getDate() &&
                      selectedDate.getMonth() === day.date.getMonth()
                    }
                    date={day.date}
                    activeModifiers={modifiers}
                    {...buttonProps}
                  />
                );
              },
            }}
            formatters={{ formatCaption, formatWeekdayName }}
            modifiers={
              dates && {
                freeShipping,
                critical,
                standard,
              }
            }
            classNames={{
              chevron: 'bg-grey',
              months: 'gap-0 flex flex-col md:flex-row',
              day_button: 'w-10 h-10',
              button_next:
                'rounded-full w-10 h-10 text-default flex items-center justify-center translate-y-[30%] cursor-pointer hover:bg-[#e7edff] z-[1] justify-end',
              button_previous:
                'rounded-full w-10 h-10 text-default flex items-center justify-center translate-y-[30%] cursor-pointer hover:bg-[#e7edff] z-[1] justify-start',
              month: 'px-4 pb-[10px] last:border-l border-neutral-100',
              nav: 'top-0 flex justify-between w-full px-4 absolute z-1',
              month_caption: 'relative block text-center pb-[10px]',
              caption_label:
                'font-hvBold text-base font-semibold z-1 inline-flex items-center m-0 py-0 px-1 relative text-current whitespace-no-wrap',
              day: 'h-10',
              weekday:
                'rounded-full w-10 h-10 text-xs font-hvBold font-semibold p-0 text-center uppercase align-middle mb-5',
            }}
            styles={{
              month: {
                marginBottom: '10px',
                borderBottom: '1px solid #F1F5F9',
                margin: 0,
                marginTop: '20px',
              },
              months: {
                background: 'white',
                borderBottomLeftRadius: dates ? '0' : '12px',
                borderBottomRightRadius: dates ? '0' : '12px',
                borderTopLeftRadius: showUPSPolicy ? '0' : '12px',
                borderTopRightRadius: showUPSPolicy ? '0' : '12px',
                justifyContent: 'center',
              },
            }}
            showOutsideDays={false}
            disabled={disabledDays}
            onDayClick={handleDaySelect}
            selected={selectedDate}
            numberOfMonths={twoMonths ? 2 : 1}
          />
          {dates && (
            <Footer
              headerClassName={headerClassName}
              showSelectButton={!!onSelectTimeClick}
              selectedDay={selectedDate}
              onSelectTimeClick={handleSelectTimeClick}
              selectedDayType={selectedDayType}
              showCriticalDates={!!critical.length}
              showStandardDates={!!standard.length}
              showFreeShippingDates={!!freeShipping.length}
              twoMonths={twoMonths}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default DatePicker;
