import * as React from 'react';
import InfoCards from 'styleguide/components/InfoCards/InfoCards';
import H3 from 'styleguide/components/Heading/H3';
import Button from 'styleguide/components/Button/Button';
import { infoCards } from 'bundles/App/pages/HomePage/types';

const WhyPrintivity = () => {
  const scrollToReviewsSetion = () => {
    const el = document.getElementById('wow');
    if (el) {
      el.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="w-full border-b border-solid border-gray-50">
      <div
        className="mx-auto flex max-w-screen-xl justify-between
            py-24 lg:pl-10 -md:w-full -md:flex-col -md:text-center"
      >
        <div className="mx-4 md:self-center md:pl-6 lg:pl-0">
          <H3 className="mb-3 !text-4xl !leading-10 !tracking-wide">
            Why <br /> Printivity?
          </H3>
          <p className="sm:paragraph-medium-desktop paragraph-medium-mobile mb-12 max-w-[530px] sm:mb-16 md:mb-4 lg:max-w-[250px] xl:max-w-[348px] -md:mx-auto">
            World-class service, dedication to your projects, and excellent quality sets us apart from the
            rest. Get the support you need to grow your business and your brand.
          </p>
          <Button onClick={scrollToReviewsSetion} className="-md:!hidden" type="button" color="blue">
            See What Customers Are Saying
          </Button>
        </div>
        <InfoCards infoCards={infoCards} grid iconSize="md" />
        <Button
          onClick={scrollToReviewsSetion}
          className="mx-6 mb-16 !text-sm xs:max-w-full sm:mx-auto sm:max-w-[192px] md:!hidden"
          color="blue"
          type="button"
        >
          See What Customers Are Saying
        </Button>
      </div>
    </div>
  );
};

export default WhyPrintivity;
