import * as React from 'react';
import cn from 'classnames';
import * as css from './Heading.scss';

const H1 = ({ children, className, ...otherProps }: React.HTMLProps<HTMLHeadingElement>) => (
  <h1 className={cn(css.Heading, css.H1, className)} {...otherProps}>
    {children}
  </h1>
);

export default H1;
