import * as React from 'react';
import cn from 'classnames';
import * as css from './InstantQuote.scss';
import Grid from 'styleguide/components/Grid/Grid';
import Image from 'styleguide/components/Image/Image';
import PageLevelErrorItem from 'styleguide/components/PageLevelError/PageLevelErrorItem';
import PageLevelError from 'styleguide/components/PageLevelError/PageLevelError';
import QuoteEditForm from 'styleguide/components/QuoteEditForm/QuoteEditForm';
import HtmlContent from 'styleguide/components/HtmlContent/HtmlContent';
import ProductSelector from '../ProductSelector/ProductSelector';
import CustomQuoteWarning from 'styleguide/components/CustomQuoteWarning/CustomQuoteWarning';
import IfDesktop from 'styleguide/components/Conditional/IfDesktop';
import { Product, CmsProductPage, CmsCategoryPage } from 'contexts/AppContext/types';
import PersephoneContext from 'contexts/PersephoneContextContainer/PersephoneContext';
import IfTabletOrDesktop from 'styleguide/components/Conditional/IfTabletOrDesktop';
import Loader from 'styleguide/components/Loader/Loader';
import IfMobile from 'styleguide/components/Conditional/IfMobile';
import QuoteContext from 'contexts/QuoteContext/QuoteContext';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './sliderStyleCustomizations.css';
import './zoom.css';
import * as galleryThumbs from './galleryThumbs.scss';
import * as modalGalleryThumbs from './modalGalleryThumbs.scss';
import ReactImageMagnify from '@blacklab/react-image-magnify';
import Modal from 'styleguide/components/Modal/Modal';
import useWindowDimensions from 'utils/dimensions';
import IconArrowLeft from 'styleguide/icons/IconArrowLeft';
import IconArrowRight from 'styleguide/icons/IconArrowRight';
import Breadcrumb from 'styleguide/components/Breadcrumb/Breadcrumb';
import camelCase from 'lodash-es/camelCase';
import { clickCarousel } from 'api/gtm';

function SlickArrowLeft(arrowProps) {
  const { className, onClick, slideIndex, selectedProduct } = arrowProps;

  return (
    <div
      className={cn(
        'z-10 !flex !h-9 !w-9 !items-center !justify-center !rounded-full !bg-default',
        'lg:opacity-5 lg:transition-opacity lg:duration-300 lg:ease-in-out lg:hover:opacity-100',
        slideIndex === 0 && '!hidden',
        className,
      )}
      onClick={() => {
        clickCarousel(
          {
            item_name: selectedProduct.name,
            item_id: selectedProduct.slug,
          },
          {
            action: 'LeftArrow',
            page: 'PDP',
          },
        );
        onClick();
      }}
      onKeyDown={onClick}
      role="button"
      tabIndex={0}
    >
      <IconArrowLeft color="white" size="sm" />
    </div>
  );
}

function SlickArrowRight(arrowProps) {
  const { className, onClick, slideIndex, images, selectedProduct } = arrowProps;

  return (
    <div
      className={cn(
        '!flex !h-9 !w-9 !items-center !justify-center !rounded-full !bg-default',
        'lg:opacity-5 lg:transition-opacity lg:duration-300 lg:ease-in-out lg:hover:opacity-100',
        slideIndex === images.length - 1 && '!hidden',
        className,
      )}
      onClick={() => {
        clickCarousel(
          {
            item_name: selectedProduct.name,
            item_id: selectedProduct.slug,
          },
          {
            action: 'RightArrow',
            page: 'PDP',
          },
        );
        onClick();
      }}
      onKeyDown={onClick}
      role="button"
      tabIndex={0}
    >
      <IconArrowRight color="white" size="sm" />
    </div>
  );
}

interface Props {
  selectedProduct: Product;
  selectedCategoryCms?: CmsCategoryPage;
  selectedProductCms?: CmsProductPage;
  products: Product[];
  cms: CmsProductPage;
  shortDescription: string;
  reviewsCount: number;
  reviewsScore: number;
  informational?: boolean;
  productSelectionHandler?: (productName: string, e: React.MouseEvent<HTMLElement>) => void;
}

const InstantQuote = ({ ...props }: Props) => {
  const persephone = React.useContext(PersephoneContext);
  const quoteContext = React.useContext(QuoteContext);
  const [showImageGalleryModal, setShowImageGalleryModal] = React.useState(false);
  const { width, height } = useWindowDimensions();
  const [slideIndex, setSlideIndex] = React.useState(0);
  const sliderRef = React.useRef<Slider>();

  const images = [
    ...(props.selectedProduct.images[camelCase(quoteContext.alias)] || []),
    ...(props.selectedProduct.images.master || []),
  ];

  React.useEffect(() => {
    if (!!sliderRef && !!sliderRef.current) {
      sliderRef.current.slickGoTo(0);
    }
  }, [props.selectedProduct]);

  const isLandscape = (): boolean => width > height;

  function renderCustomPaging(i) {
    const img = images[i];
    return (
      <a>
        <Image title={props.cms.label} alt={img?.alt} url={img?.url.mini} />
      </a>
    );
  }

  function appendDots(dots) {
    return (
      <ul>
        {dots.map((item, index) => (
          <li className={item.props.className} key={index}>
            {item.props.children}
          </li>
        ))}
      </ul>
    );
  }

  const settings = {
    customPaging: renderCustomPaging,
    dots: true,
    dotsClass: galleryThumbs.thumbnails,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    lazyLoad: true,
    afterChange: current => setSlideIndex(current),
    nextArrow: (
      <SlickArrowRight slideIndex={slideIndex} images={images} selectedProduct={props.selectedProduct} />
    ),
    prevArrow: <SlickArrowLeft slideIndex={slideIndex} selectedProduct={props.selectedProduct} />,
  };

  const modalSettings = {
    customPaging: renderCustomPaging,
    dots: true,
    dotsClass: isLandscape()
      ? modalGalleryThumbs.modalThumbnailsVertical
      : modalGalleryThumbs.modalThumbnails,
    appendDots,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    fade: true,
    accessibility: true,
    initialSlide: slideIndex,
    nextArrow: <SlickArrowRight />,
    prevArrow: <SlickArrowLeft />,
  };

  return (
    <Grid.Container>
      {!!showImageGalleryModal && (
        <Modal shown fullWidth hideScroll onClose={() => setShowImageGalleryModal(false)}>
          {/* The classnames are used scope for modal version. Slick-slider does not support modules */}
          <div className={cn('galleryModal', isLandscape() ? 'landscape' : '', css.modalContentContainer)}>
            <Slider {...modalSettings}>
              {images.map(image => (
                <div className={css.modalImageWrapper}>
                  <Image
                    title={props.cms.label}
                    alt={image.alt}
                    url={image.url.large}
                    className={cn(css.image)}
                  />
                </div>
              ))}
            </Slider>
          </div>
        </Modal>
      )}
      <Grid>
        <Grid.Row className={css.instantQuote}>
          <Grid.Col md={6} lg={6} className={css.imageWrapper}>
            <div className={css.imageWithError}>
              {props.selectedCategoryCms.label.toLowerCase() !== 'landing' && (
                <Breadcrumb
                  className="text-center md:text-left"
                  pages={[
                    { label: 'Home', link: '/' },
                    { label: props.selectedCategoryCms.label, link: props.selectedCategoryCms.fullPath },
                    { label: props.selectedProductCms.label },
                  ]}
                />
              )}
              <Grid.Row className={css.productTitleContainer}>
                <Grid.Col sm={12}>
                  <h1 className={css.productTitle}>{props.cms.label}</h1>
                  <IfDesktop>{quoteContext.status === 'customQuote' && <CustomQuoteWarning />}</IfDesktop>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col>
                  {images && images.length === 1 && (
                    <Image
                      title={props.cms.label}
                      alt={props.selectedProduct.image.alt}
                      url={props.selectedProduct.image.url}
                      className={css.image}
                    />
                  )}
                  {images && images.length > 1 && (
                    <div className="-sm:h-[260px] lg:min-h-128">
                      <Slider {...settings} ref={sliderRef}>
                        {images.map(image => (
                          <>
                            <IfMobile>
                              <ReactImageMagnify
                                {...{
                                  imageProps: {
                                    alt: image.alt,
                                    src: image.url.product,
                                    width: '100%',
                                    height: 217,
                                    fetchpriority: 'high',
                                  },
                                  magnifiedImageProps: {
                                    src: image.url.large,
                                    width: 1200,
                                    height: 1200,
                                  },
                                  activationInteractionHint: 'hover',
                                }}
                              />
                            </IfMobile>
                            <IfTabletOrDesktop className={css.imageSlideContainer}>
                              <Image
                                title={props.cms.label}
                                alt={image.alt}
                                url={image.url.product}
                                className="block contain w-inherit md:h-96 lg:h-128"
                                onClick={() => {
                                  clickCarousel(
                                    {
                                      item_name: props.selectedProduct.name,
                                      item_id: props.selectedProduct.slug,
                                    },
                                    {
                                      action: 'ZoomIn',
                                      page: 'PDP',
                                    },
                                  );
                                  setShowImageGalleryModal(true);
                                }}
                                fetchpriority="high"
                              />
                            </IfTabletOrDesktop>
                          </>
                        ))}
                      </Slider>
                    </div>
                  )}
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col>
                  {!!quoteContext.errorMessage && (
                    <PageLevelError message="Failed to get quote price">
                      <PageLevelErrorItem>{quoteContext.errorMessage}</PageLevelErrorItem>
                    </PageLevelError>
                  )}
                  {props.shortDescription && (
                    <>
                      <div className="caption-uppercase pt-5 text-gray-500">Description</div>
                      <HtmlContent className={css.featuresContent} content={props.shortDescription} />
                    </>
                  )}
                </Grid.Col>
              </Grid.Row>
            </div>
          </Grid.Col>
          {!props.informational && (
            <>
              <Grid.Col hiddenLg="down" lg={1} />
              <Grid.Col sm={12} md={6} lg={5}>
                <Grid.Row>
                  <Grid.Col sm={12} className={css.rightPanel}>
                    <div className="mb-6 text-center font-hvBold text-2xl md:mt-10 md:text-left -md:mb-10">
                      Instant Price Quoter
                    </div>
                    {props.cms.productTiles && props.cms.productTiles.productTiles.length > 1 && (
                      <ProductSelector
                        productSelectionHandler={props.productSelectionHandler}
                        selectedProduct={props.selectedProduct}
                        products={props.products}
                      />
                    )}
                    <QuoteEditForm singleColumn showPriceAndCartButton />
                    {persephone.available && !persephone.catalog && (
                      <>
                        <IfTabletOrDesktop className={cn(css.loaderContainer)}>
                          <Loader size="xxl" />
                        </IfTabletOrDesktop>
                        <IfMobile className={cn(css.loaderContainer)}>
                          <Loader size="md" />
                        </IfMobile>
                      </>
                    )}
                    {!persephone.available && (
                      <div className={css.instantQuoterLoadError}>
                        <PageLevelError message="Instant quoter failed to load">
                          <PageLevelErrorItem>
                            Please refresh the page. If that does not work, please try again later.
                          </PageLevelErrorItem>
                        </PageLevelError>
                      </div>
                    )}
                  </Grid.Col>
                </Grid.Row>
              </Grid.Col>
            </>
          )}
        </Grid.Row>
      </Grid>
    </Grid.Container>
  );
};
export default InstantQuote;
