import * as React from 'react';
import cn from 'classnames';
import * as css from './MobileMenuItem.scss';
import Span from 'styleguide/components/Span/Span';
import A from 'styleguide/components/Links/A';
import Link from 'styleguide/components/Links/Link';
import MobileNavContext, { MobileNavContextType } from '../../MobileNavContext';

interface Props {
  title?: string;
  Img?: React.ReactNode;
  dontCloseOnClick?: boolean; // TODO: extract menu block header into the separate component
  withPadding?: boolean;
  Icon?: React.ReactNode;
  theme?: 'blue' | 'lightBlue' | 'white';
  thin?: boolean;
  href?: string;
  linkTo?: string;
  withBorder?: boolean;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  bold?: boolean;
  className?: string;
}

const MobileMenuItem = ({
  dontCloseOnClick,
  title,
  thin = false,
  Icon,
  theme = 'blue',
  href,
  withBorder,
  linkTo,
  onClick,
  bold,
  Img,
  className,
}: Props) => (
  <MobileNavContext.Consumer>
    {(payload: MobileNavContextType) => (
      <li
        className={cn(
          'text-sm py-3 px-5 flex justify-between items-center hover:cursor-pointer',
          css[`MobileMenuItem--${theme}`],
          withBorder ? 'border-b border-solid border-gray-50' : '',
          thin && 'py-2',
          className,
        )}
      >
        {!!linkTo && (
          <Link
            color={theme === 'blue' ? 'blue' : 'black'}
            underline="none"
            data-cy={`mobilenav-${linkTo.replace('/', '')}`}
            to={linkTo}
            className={cn(
              'text-base',
              theme === 'blue' ? 'text-white' : 'text-default',
              bold ? '!font-hvBold' : '!font-hvRoman',
              bold && theme === 'white' && 'text-black',
            )}
            onClick={e => {
              if (dontCloseOnClick !== true) {
                payload.onClose();
              }
              if (onClick) {
                onClick(e);
              }
            }}
          >
            {title || Img}
          </Link>
        )}
        {!!href && (
          <A
            href={href}
            color={theme === 'blue' ? 'blue' : 'black'}
            underline="none"
            className={cn(
              'text-base',
              theme === 'blue' ? 'text-white' : 'text-default',
              bold ? 'font-hvBold' : 'font-hvRoman',
              bold && theme === 'white' && 'text-black',
            )}
            onClick={e => {
              if (dontCloseOnClick !== true) {
                payload.onClose();
              }
              if (onClick) {
                onClick(e);
              }
            }}
          >
            {title}
          </A>
        )}
        {!linkTo && !href && (
          <Span
            className={cn(
              'text-base',
              theme === 'blue' ? 'text-white' : 'text-default',
              bold ? 'font-hvBold' : 'font-hvRoman',
              bold && theme === 'white' && 'text-black',
            )}
            onClick={e => {
              if (dontCloseOnClick !== true) {
                payload.onClose();
              }
              if (onClick) {
                onClick(e);
              }
            }}
          >
            {title}
          </Span>
        )}
        {Icon}
      </li>
    )}
  </MobileNavContext.Consumer>
);

export default MobileMenuItem;
