import * as React from 'react';
import cn from 'classnames';
import * as css from './UL.scss';

export type LiIconSize = 'small';
export type LiIconColor = 'black' | 'white' | 'orange' | 'dark' | 'blue';

export interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLLIElement>, HTMLLIElement> {
  iconSize?: LiIconSize | 'small';
  iconColor?: LiIconColor;
}

export const LI = ({ iconSize, iconColor, ...props }: Props) => (
  <li
    className={cn(
      css.element,
      { [css[`element--${iconSize}`]]: !!iconSize },
      { [css[`element--${iconColor}`]]: !!iconColor },
      props.className,
    )}
  >
    {props.children}
  </li>
);

const UL = ({ ...props }) => <ul className={cn(css.list, props.className)}>{props.children}</ul>;

UL.LI = LI;

export default UL;
