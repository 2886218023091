import * as React from 'react';
import cn from 'classnames';
import { Button as HButton, ButtonProps as HButtonProps } from '@headlessui/react';
import { ButtonColor, ButtonSize } from './types';
import ButtonAsNativeLink from './ButtonAsNativeLink';
import * as css from './Button.scss';

export interface ButtonProps extends HButtonProps<'button'> {
  type: 'submit' | 'button';
  color: ButtonColor;
  expanded?: boolean;
  size?: ButtonSize;
  admin?: boolean;
  outline?: boolean;
  dataCy?: string;
}

const Button = ({
  type,
  color,
  expanded,
  disabled,
  className,
  admin,
  children,
  size,
  outline,
  dataCy,
  ...props
}: ButtonProps) => (
  <HButton
    // eslint-disable-next-line react/button-has-type
    type={type}
    data-cy={dataCy}
    className={cn(
      css.button,
      css[`color--${color}`],
      [css[`size--${size}`]],
      {
        [css.expanded]: !!expanded,
        [css[`disabled--${color}`]]: !!disabled,
        [css[`outline--${color}`]]: !!outline,
        [css[`outline--disabled--${color}`]]: !!outline,
        [css.admin]: !!admin,
      },
      className as string,
    )}
    {...props}
    disabled={disabled}
  >
    {children}
  </HButton>
);

Button.AsNativeLink = ButtonAsNativeLink;

export default Button;
