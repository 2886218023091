import * as React from 'react';
import cn from 'classnames';
import * as css from './ProofApprovalsPage.scss';
import Grid from 'styleguide/components/Grid/Grid';
import Span from 'styleguide/components/Span/Span';
import CustomerServiceContact from './CustomerServiceContact';
import HtmlContent from 'styleguide/components/HtmlContent/HtmlContent';
import NavigationButtons from 'bundles/App/pages/ProofApprovalsPage/NavigationButtons';
import { Reservation } from 'api/admin/orders/types';

export interface Props {
  reservation: Reservation;
}

const Approved = ({ reservation }: Props) => (
  <>
    <Grid.Row>
      <Grid.Col>
        <Span data-cy="proofApprovalApprovedPageTitle" className={cn(css.title, css.approved)}>
          Proof Approved!
        </Span>
      </Grid.Col>
    </Grid.Row>
    <Grid.Row>
      <Grid.Col>
        <HtmlContent
          className={css.bodyText}
          content={
            "You're all set! Your order has been placed into the production queue, and we'll get started on it shortly."
          }
        />
        <HtmlContent
          content={
            'If you have questions about your order, feel free to contact your dedicated customer service representative.\n'
          }
        />
        <CustomerServiceContact reservation={reservation} />
      </Grid.Col>
    </Grid.Row>
    <NavigationButtons />
  </>
);

export default Approved;
