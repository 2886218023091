import * as React from 'react';
import MultiItemCarousel from 'styleguide/components/Carousel/MultiItemCarousel';
import { designServicesImages } from 'bundles/App/pages/service/DesignServices/designServicesData';
import Image from 'styleguide/components/Image/Image';

const OurLatestWork = () => (
  <div className="relative mx-auto max-w-8xl pb-36 pt-24 text-center xl:pb-48 xl:pt-48">
    <h4 className="heading-bold-sm mx-auto">Check out our latest work.</h4>
    <p className="paragraph-medium-desktop mx-auto mb-8 mt-2 max-w-4xl">
      See what we’ve done for our clients.
    </p>
    <MultiItemCarousel scrollDistance={396}>
      {designServicesImages.map((image, i: number) => (
        <div className="min-w-[396px] h-96 overflow-hidden" key={i}>
          <Image
            key={`${image.alt}_${i}`}
            className="borders-solid relative h-96 w-96 rounded-lg border border-neutral-200 object-cover p-2 hover:shadow-md box-border"
            width={384}
            height={384}
            alt={image.alt}
            title={image.title}
            url={image.url}
            lazyload
          />
        </div>
      ))}
    </MultiItemCarousel>
  </div>
);

export default OurLatestWork;
