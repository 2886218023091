import * as React from 'react';
import cn from 'classnames';
import * as css from './CheckoutNavbar.scss';
import Grid from 'styleguide/components/Grid/Grid';
import Image from 'styleguide/components/Image/Image';
import IconArrowLeft from 'styleguide/icons/IconArrowLeft';
import logo from 'assets/images/printivity-logo-mobile.png';
import { getCurrentOrder } from 'contexts/OrderContext/actions';
import OrderContext from 'contexts/OrderContext/OrderContext';
import { cartPath } from 'bundles/App/routes';
import { useHistory } from 'react-router-dom';

const CheckoutNavbar = () => {
  const history = useHistory();
  const { dispatch } = React.useContext(OrderContext);
  const handleContinue = () => {
    getCurrentOrder(dispatch, cartPath);
    history.push(cartPath);
  };

  return (
    <div>
      <Grid.Container className={cn(css.Nav, 'border-b border-solid border-gray-50 -md:py-6')}>
        <Grid noPadding className={cn(css.NavGrid, 'max-w-screen-xl')}>
          <Grid.Row className={cn(css.row)}>
            <div className="mx-10 flex w-full items-center justify-between">
              <div className={cn(css.leftSide)}>
                <div
                  className="flex cursor-pointer items-center"
                  onClick={handleContinue}
                  onKeyDown={handleContinue}
                  role="button"
                  tabIndex={0}
                >
                  <IconArrowLeft className="mr-5 !h-4 !w-2" />
                  <div className="paragraph-bold-mobile w-16 text-default">Continue Shopping</div>
                </div>
              </div>
              <div className={cn(css.leftSide)}>
                <Image url={logo} title="Printivity" alt="Printivity" />
              </div>
              <div className={cn(css.helpTextContainer, '-md:!hidden')}>
                <p className={cn(css.helpText)}>Need help? 1-877-649-5463</p>
              </div>
            </div>
          </Grid.Row>
        </Grid>
      </Grid.Container>
    </div>
  );
};

export default CheckoutNavbar;
