import * as React from 'react';
import cn from 'classnames';
import * as css from './Field.scss';
import Grid from 'styleguide/components/Grid/Grid';

interface Props {
  children?: React.ReactNodeArray;
  className?: string;
}

const ThreeColumnFormField = ({ children, className = '' }: Props) => (
  <Grid.Row className={cn(css.zeroMarginPadding, className)}>
    <Grid.Col sm={12} md={4} className="!pl-0 -md:!pr-0">
      {children[0]}
    </Grid.Col>
    <Grid.Col sm={12} md={4} className="!pl-0 !pr-0">
      {children[1]}
    </Grid.Col>
    <Grid.Col sm={12} md={4} className="!pr-0 -md:!pl-0">
      {children[2]}
    </Grid.Col>
  </Grid.Row>
);

export default ThreeColumnFormField;
