import * as React from 'react';
import cn from 'classnames';
import * as css from './Slider.scss';

interface Props {
  marginLeft?: string;
  imgWidth: number;
  imgHeight?: number;
  carouselImages: {
    url: string;
    alt: string;
    title: string;
  }[];
  wrapperClassName?: string;
  sliderHeightClassName?: string;
  sliderElClassName?: string;
  innerPadding?: number;
  slideCount?: number;
}

const OurStorySlider = ({
  marginLeft,
  carouselImages,
  imgHeight,
  imgWidth,
  wrapperClassName,
  sliderHeightClassName,
  sliderElClassName,
  innerPadding,
  slideCount,
}: Props) => (
  <div className={wrapperClassName}>
    <div
      className={cn(css.slider, sliderHeightClassName)}
      style={
        {
          '--slide-width': `${imgWidth + innerPadding}px`,
          '--slide-count': slideCount,
          '--slide-height': `${imgHeight}px`,
        } as React.CSSProperties
      }
    >
      <div className={cn(css.slideTrack, marginLeft && marginLeft)}>
        {carouselImages.map(image => (
          <div className={cn('w-[444px] h-[400px]', sliderElClassName)} key={image.url}>
            <img
              src={image.url}
              height={imgHeight || 420}
              width={imgWidth || 250}
              alt={image.alt}
              className="rounded-3xl mr-6 object-cover"
            />
          </div>
        ))}
        {carouselImages.map(image => (
          <div className={cn('w-[444px] h-[400px]', sliderElClassName)} key={image.url}>
            <img
              src={image.url}
              height={imgHeight || 420}
              width={imgWidth || 250}
              alt={image.alt}
              className="rounded-3xl mr-6 object-cover"
            />
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default OurStorySlider;
