import * as React from 'react';
import cn from 'classnames';
import * as css from './Sidebar.scss';
import { Section } from './types';
import A from 'styleguide/components/Links/A';

interface Props {
  selectedSection: Section;
  sections: Section[];
}

function Sidebar({ sections, selectedSection }: Props) {
  return (
    <div className="flex flex-row flex-wrap px-12 text-center lg:flex-col lg:text-left">
      {sections.map((section, index) => (
        <A
          className={cn('basis-full sm:basis-1/2 md:basis-1/3 lg:basis-full', css.link, {
            [css.selectedLink]: selectedSection.slug === section.slug,
          })}
          key={index}
          href={`/help-center/${section.slug}`}
          color="black"
          underline="none"
        >
          {section.name}
        </A>
      ))}
    </div>
  );
}

export default Sidebar;
