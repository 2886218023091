// extracted by mini-css-extract-plugin
var _1 = "HeadingWithAddressSelector__buttonSelectWrapper__gCCec";
var _2 = "HeadingWithAddressSelector__copyLink__n9nMB";
var _3 = "HeadingWithAddressSelector__copyLinkWrapper__ZpqtR";
var _4 = "HeadingWithAddressSelector__formHeading__AEJNY";
var _5 = "HeadingWithAddressSelector__formHeadingAdmin__kPeIu";
var _6 = "HeadingWithAddressSelector__formHeadingContainerAdmin__wXM1o";
var _7 = "HeadingWithAddressSelector__headingWithAddressSelector__P0xe5";
var _8 = "HeadingWithAddressSelector__selectWrapper__B7YZp";
export { _1 as "buttonSelectWrapper", _2 as "copyLink", _3 as "copyLinkWrapper", _4 as "formHeading", _5 as "formHeadingAdmin", _6 as "formHeadingContainerAdmin", _7 as "headingWithAddressSelector", _8 as "selectWrapper" }
