// extracted by mini-css-extract-plugin
var _1 = "Heading__H1__fFvYD";
var _2 = "Heading__H2__AjHxx";
var _3 = "Heading__H3__qt4UR";
var _4 = "Heading__H4__okv2H";
var _5 = "Heading__H4--light__ctQWQ";
var _6 = "Heading__H5__egliJ";
var _7 = "Heading__H5--light__ws7gX";
var _8 = "Heading__H6___WhM4";
var _9 = "Heading__Heading__ksTvS";
export { _1 as "H1", _2 as "H2", _3 as "H3", _4 as "H4", _5 as "H4--light", _6 as "H5", _7 as "H5--light", _8 as "H6", _9 as "Heading" }
