import * as React from 'react';
import * as css from './ProofApprovalsPage.scss';
import Grid from 'styleguide/components/Grid/Grid';
import RadioTabs from 'styleguide/components/Formik/RadioTabs';
import { FileConcern } from './types';
import HtmlContent from 'styleguide/components/HtmlContent/HtmlContent';
import FieldWrapper from 'styleguide/components/Formik/FieldWrapper/FieldWrapper';
import { Tooltip } from 'styleguide/components/Tooltip/Tooltip';
import { IconTooltipQuestion } from 'styleguide/icons';
import { FormikValues, useFormikContext } from 'formik';
import { FormikFieldWrapper } from 'app/styleguide/components/Formik';

export interface Props {
  index: number;
  fileConcern: FileConcern;
}

const ProofApprovalFileConcern = ({ index, fileConcern }: Props) => {
  const { values, errors, touched, setFieldValue } = useFormikContext<FormikValues>();

  return (
    <Grid.Row className={css.fileConcern}>
      <Grid.Col sm={12}>
        <Grid.Row className="md:!justify-start justify-start">
          {index + 1}. {fileConcern.message}{' '}
          {!!fileConcern.detailedMessage && (
            <div className="flex ml-1">
              <Tooltip
                placement="top"
                content={<HtmlContent content={fileConcern.detailedMessage} />}
                className="z-[5] w-max max-w-[500px] rounded-lg bg-shades-0 px-5 py-4 text-sm shadow-xl !text-default"
                action="click"
                withArrow
                renderOpener={tooltipProps => (
                  <div {...tooltipProps} className="cursor-pointer">
                    <IconTooltipQuestion color="dark" size="sm" fill="none" />
                  </div>
                )}
              />
            </div>
          )}
        </Grid.Row>
        <Grid.Row className={css.fileConcernForm}>
          <Grid.Col md={4} sm={12}>
            <FieldWrapper
              Input={
                <RadioTabs
                  className={css.tabs}
                  name={`proofApprovalFileConcernsAttributes.${index}.approved`}
                  errors={errors}
                  touched={touched}
                  onChange={newValue => {
                    if (newValue) {
                      setFieldValue(`proofApprovalFileConcernsAttributes.${index}.customerResponse`, '');
                    }
                  }}
                >
                  <FormikFieldWrapper
                    name={`proofApprovalFileConcernsAttributes.${index}.approved`}
                    labelComponent="Approve"
                    value
                    componentType="radio"
                  />
                  <FormikFieldWrapper
                    name={`proofApprovalFileConcernsAttributes.${index}.approved`}
                    labelComponent="Reject"
                    value={false}
                    componentType="radio"
                  />
                </RadioTabs>
              }
            />
          </Grid.Col>
          <Grid.Col md={8} sm={12}>
            <FormikFieldWrapper
              name={`proofApprovalFileConcernsAttributes.${index}.customerResponse`}
              disabled={values.proofApprovalFileConcernsAttributes[index].approved === true}
              rows={5}
              componentType="textarea"
              labelComponent="Comments"
            />
          </Grid.Col>
        </Grid.Row>
      </Grid.Col>
    </Grid.Row>
  );
};

export default ProofApprovalFileConcern;
