import * as React from 'react';
import cn from 'classnames';
import Image from '../Image/Image';

interface Props {
  intro?: string;
  subtitle?: string;
  title: string;
  titleStyle?: string;
  descriptionStyle?: string;
  description: JSX.Element;
  imageUrlMobile: string;
  imageUrlDesktop: string;
  imageTitle: string;
  imageAlt: string;
  direction: 'left' | 'right' | 'top' | 'bottom';
}

function getImageOrder(textOrder: string | 'left') {
  return textOrder === 'left' ? 'right' : 'left';
}

const Story = (props: Props) => {
  const {
    intro,
    subtitle,
    title,
    titleStyle,
    descriptionStyle,
    description,
    imageUrlMobile,
    imageUrlDesktop,
    imageTitle,
    imageAlt,
    direction = 'left',
  } = props;
  const imagePosition = getImageOrder(direction);
  const imageStyle = {
    left: 'xs:left-[12px] lg:left-[16px] 2xl:left-[20px]',
    right: 'xs:-right-[12px] lg:-right-[16px] 2xl:-right-[20px]',
    top: 'xs:-bottom-[12px] lg:-bottom-[16px] 2xl:-bottom-[20px]',
    bottom: 'xs:-top-[12px] lg:-top-[16px] 2xl:-top-[20px]',
  };
  const backgroundImageStyle = {
    left: ' ml-[10px] md:ml-4 md:ml-6',
    right: 'mr-[10px] md:mr-4 md:mr-6',
    top: 'mt-[10px] md:mt-4 md:mt-6',
    bottom: 'mb-[10px] md:mb-4 md:mb-6',
  };
  const imageWrapperStyle = {
    left: 'order-2  xs:ml-0 md:-ml-[100px] md:-ml-[60px] md:ml-0 ',
    right: 'order-3 xs:mr-0 md:-mr-[100px] md:-mr-[60px] md:mr-0 ',
    top: '',
    bottom: '',
  };
  const descriptionWrapperStyle = {
    left: 'order-2 justify-start xs:ml-5 lg:pr-[80px] 3xl:pr-52 xl:ml-0 ml-[10px] md:ml-4 md:ml-6',
    right: 'order-3 justify-end xs:ml-5 lg:pl-[80px] 3xl:pl-52  xl:ml-0 mr-[10px] md:mr-4 md:mr-6',
  };

  return (
    <div className="flex md:gap-4 my-10 flex-nowrap overflow-hidden p-0 first:mt-16 last:mb-20 md:justify-center md:last:mb-32 -md:flex-col-reverse">
      <div
        className={cn(
          'z-1 text-center md:flex md:flex-row md:flex-wrap md:content-center md:items-start md:justify-start md:text-left -md:p-5',
          descriptionWrapperStyle[direction],
        )}
      >
        <div className="xs:w-full md:max-w-[412px] 3xl:max-w-[448px]">
          {intro && (
            <div className="w-full font-hvBold text-xl md:w-1/2 md:text-4xl lg:text-5xl -md:mt-6">
              {intro}
            </div>
          )}
          <div className={cn('font-hvBold text-6xl text-blue xs:mb-2 md:mb-6 lg:text-9xl', titleStyle)}>
            {title}
          </div>
          {subtitle && <div className="heading-bold-xs">{subtitle}</div>}
          <div className={cn('paragraph-medium-desktop', descriptionStyle)}>{description}</div>
        </div>
      </div>
      <div
        className={cn(
          'flex flex-row content-center items-center -md:!hidden',
          imageWrapperStyle[imagePosition],
        )}
      >
        <div
          className={cn(
            'rounded-xl bg-blue md:max-h-[360px] lg:max-h-[480px] xl:max-h-[730px] md:max-w-[360px] lg:max-w-[480px] xl:max-w-[730px]',
            backgroundImageStyle[imagePosition],
          )}
        >
          <div className="flex xs:py-3 lg:py-4 2xl:py-5">
            <Image
              className={cn(
                'relative h-full w-full rounded-xl md:max-h-[320px] lg:max-h-[440px] xl:max-h-[680px] md:max-w-[320px] lg:max-w-[440px] xl:max-w-[680px]',
                imageStyle[imagePosition],
                'object-cover',
              )}
              title={imageTitle}
              alt={imageAlt}
              url={imageUrlDesktop}
            />
          </div>
        </div>
      </div>
      <div className="relative order-5 rounded-xl bg-blue text-right shadow-lg md:hidden">
        <div className="flex py-3">
          <Image
            className="relative h-full w-full rounded-xl object-cover"
            title={imageTitle}
            alt={imageAlt}
            url={imageUrlMobile}
          />
        </div>
      </div>
    </div>
  );
};

export default Story;
