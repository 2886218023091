import * as React from 'react';
import * as css from './PaymentPortalPage.scss';
import Grid from 'styleguide/components/Grid/Grid';
import Span from 'styleguide/components/Span/Span';
import TitleContainer from '../Account/TitleContainer/TitleContainer';
import Footer from 'styleguide/components/Footer/Footer';
import { useParams } from 'react-router';

const PaymentSuccessPage = () => {
  const { orderNumber } = useParams<{ orderNumber: string }>();

  return (
    <>
      <TitleContainer title="Payment Portal" />
      <Grid.Container>
        <Grid>
          <Grid.Row className={css.paymentSuccessTitle}>
            <Grid.Col>
              <Span className={css.bold}>Thank you for your payment! </Span>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col>
              <Span>
                Your payment for order # {orderNumber} has been processed. You wil receive an email shortly
                shortly with your receipt.{' '}
              </Span>
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </Grid.Container>
      <Footer />
    </>
  );
};

export default PaymentSuccessPage;
