import * as React from 'react';
import cn from 'classnames';
import * as css from './SimplyShip.scss';
import DeliveryDetail from './DeliveryDetail/DeliveryDetail';
import Span from 'styleguide/components/Span/Span';
import OrderContext, { SimplyShipError } from 'contexts/OrderContext/OrderContext';
import SimplyShipContext from 'contexts/SimplyShipContext/SimplyShipContext';
import AppContext from 'contexts/AppContext/AppContext';
import { H5 } from 'styleguide/components/Heading';
import Grid from 'styleguide/components/Grid/Grid';
import InPlaceMessage from 'styleguide/components/InPlaceMessage/InPlaceMessage';
import { Address } from 'bundles/App/pages/Account/Addresses/types';
import SmartyAutocomplete from 'styleguide/components/SmartyAutocomplete/SmartyAutocomplete';

interface Props {
  checkoutPageMode?: boolean;
  disableInput?: boolean;
}

function isSimplyShipError(object: unknown | string): object is SimplyShipError {
  return typeof object === 'object' && 'message' in object && !!object.message;
}

const SimplyShip = ({ checkoutPageMode, disableInput }: Props) => {
  const appContext = React.useContext(AppContext);
  const { order } = React.useContext(OrderContext);
  const simplyShipContext = React.useContext(SimplyShipContext);
  const [address, setAddress] = React.useState<Address>(
    checkoutPageMode ? order.shipments[0]?.address : null,
  );

  const hideDeliveryDetail =
    (isSimplyShipError(order.status) && appContext.store.admin === false) || (!address && !checkoutPageMode);

  React.useEffect(() => {
    if (address) {
      simplyShipContext.onAddressChange(address);
    }
  }, [address]);

  return (
    <>
      {!checkoutPageMode && (
        <Grid.Container className={cn(css.container)}>
          <Grid.Row className={cn(css.row)}>
            <Grid.Col className={cn(css.headingContainer)} sm={12} md={6}>
              <H5 data-cy="costEstimatorTitle" className={cn(css.mainHeading)}>
                Simply Ship™️ Cost Estimator
              </H5>
              <p data-cy="costEstimatorDescription" className={cn(css.mainDescription)}>
                Find the perfect balance between product cost, production time, and shipping speed.
              </p>
            </Grid.Col>
            <Grid.Col className={cn(css.inputContainer)} sm={12} md={6}>
              <Grid.Row>
                <Grid.Col md={12} sm={12}>
                  <>
                    <div className={cn(css.inputFieldContainer)} data-cy="estimatorAddressInput">
                      <SmartyAutocomplete setAddress={setAddress} disableInput={disableInput} />
                      <Span className="text-xs">
                        *US addresses only. For international quotes, continue to check out.{' '}
                      </Span>
                    </div>
                    {isSimplyShipError(order.status) && order.status.message && (
                      <InPlaceMessage
                        data-cy="estimatorAddressErrorText"
                        messageType="error"
                        message={order.status.message}
                        requireTouch={false}
                      />
                    )}
                  </>
                </Grid.Col>
              </Grid.Row>
            </Grid.Col>
          </Grid.Row>
        </Grid.Container>
      )}
      {!!simplyShipContext.datesChanged && (
        <Span
          data-cy="deliveryTimeChangedNotice"
          className={cn(css.datesChanged, !!checkoutPageMode && 'mt-2')}
        >
          {`Your original delivery date is not available for your current cart. We have updated ` +
            `your date from ${simplyShipContext.datesChanged.old} to ${simplyShipContext.datesChanged.new}`}
        </Span>
      )}
      {checkoutPageMode && isSimplyShipError(order.status) && (
        <Span
          data-cy="costEstimatorSimplyShipError"
          className={cn(css.simplyShipError, !!checkoutPageMode && 'mt-2')}
        >
          {order.status.message}
        </Span>
      )}
      {!hideDeliveryDetail && <DeliveryDetail checkoutPageMode={checkoutPageMode} />}
    </>
  );
};

export default SimplyShip;
