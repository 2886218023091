import * as React from 'react';
import * as css from './PriceAndButtons.scss';
import Grid from 'styleguide/components/Grid/Grid';
import Span from 'styleguide/components/Span/Span';
import { formatMoney } from 'utils/money';
import { Button } from 'styleguide/components';
import { ErrorMessage } from 'styleguide/components/forms';
import QuoteContext from 'contexts/QuoteContext/QuoteContext';
import { useFormikContext } from 'formik';
import isEqual from 'lodash-es/isEqual';
import { KeyVal } from 'libs/utils/common-types';
import AppContext from 'contexts/AppContext/AppContext';
import { cartEditItemDetails } from 'api/gtm';

interface Props {
  cancelable: boolean;
  onCancel?: () => void;
  disabled: boolean;
  priceBefore: number;
  priceAfter: number;
  errorMessage?: string;
  lineItemName?: string;
}

const PriceAndButtons = ({
  errorMessage,
  cancelable,
  onCancel,
  disabled,
  priceBefore,
  priceAfter,
  lineItemName,
}: Props) => {
  const quoteContext = React.useContext(QuoteContext);
  const appContext = React.useContext(AppContext);
  const { values } = useFormikContext<KeyVal>();

  const isDifferent = () => !isEqual(quoteContext.initialValues, values);

  const getValueDiff = (newValues, initialValues) => {
    const diff = {};
    Object.keys(newValues).forEach(key => {
      if (newValues[key] !== initialValues[key]) {
        diff[`old_${key}`] = initialValues[key];
        diff[`new_${key}`] = newValues[key];
      }
    });
    return diff;
  };

  return (
    <Grid.Container>
      <Grid noPadding>
        <Grid.Row>
          {!!errorMessage && (
            <Grid.Col md={6} sm={6}>
              <ErrorMessage message={errorMessage} data-cy="editFormErrorMsg" />
            </Grid.Col>
          )}
          {!errorMessage && (
            <Grid.Col md={6} sm={6} className={css.priceCol}>
              <Span className={css.priceTitle}>Price</Span>
              <Span data-cy="editFormPriceAfter">{formatMoney(priceAfter)}</Span>
              {priceAfter !== priceBefore && (
                <Span className={css.priceBefore}>
                  <span data-cy="editFormPriceBefore">{formatMoney(priceBefore)} </span>(before)
                </Span>
              )}
            </Grid.Col>
          )}
          <Grid.Col md={6} sm={6} className={css.buttonCol}>
            {!!cancelable && (
              <Button
                className="mr-3"
                type="button"
                color="dark"
                outline
                onClick={() => onCancel()}
                dataCy="editFormCancelButton"
              >
                Cancel
              </Button>
            )}
            {appContext.store.admin && (
              <Button
                className="mr-20"
                type="button"
                color="blue"
                onClick={() => {
                  quoteContext.onCustomize();
                }}
                disabled={!quoteContext.canCustomize}
              >
                Convert to Custom Line Item
              </Button>
            )}
            <Button
              className="mr-20"
              type="button"
              color="blue"
              disabled={!isDifferent || !!disabled || !!errorMessage}
              onClick={() => {
                cartEditItemDetails({
                  line_item_name: lineItemName,
                  default_line_item_price: priceBefore,
                  updated_Line_item_price: priceAfter,
                  ...getValueDiff(values, quoteContext.initialValues),
                });
                quoteContext.onSubmit(values);
              }}
              dataCy="editFormUpdateButton"
            >
              {quoteContext.initialValues ? 'Update' : 'Create'}
            </Button>
          </Grid.Col>
        </Grid.Row>
      </Grid>
    </Grid.Container>
  );
};

export default PriceAndButtons;
