import * as React from 'react';

// eslint-disable-next-line no-shadow
export enum ScreenSize {
  SM = 'SM',
  MD = 'MD',
  LG = 'LG',
}

export interface ScreenSizeContextType {
  screenSize: ScreenSize;
}

const ScreenSizeContext = React.createContext<ScreenSizeContextType>(null);

export default ScreenSizeContext;
